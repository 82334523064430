import React from "react"
import _ from "lodash"

const PortLink = ({ visit }): JSX.Element => {
  if (_.isNil(visit.port.id)) {
    return <>At Sea</>
  }

  if (visit.missingTourBrands.length > 0) {
    return (
      <span className="d-flex gap-1">
        <span className="text-wrap">{visit.port.name}</span>
        <span
          className="cursor-pointer"
          data-toggle="tooltip"
          data-placement="top"
          title={`Brands with no contracted tours on this date: ${_.join(visit.missingTourBrands, ", ")}`}
        >
          <i className="fa fa-exclamation-triangle text-danger" />
        </span>
      </span>
    )
  }

  return <>{visit.port.name}</>
}

export default PortLink
