import _ from "lodash"

import {
  ReservedFields,
  SettlementMethods,
  TourLocation,
} from "@/onboarding/features/product/models/Product"
import { BidStatuses } from "@/onboarding/features/product/models/Bid"
import { nullField } from "@/onboarding/features/product/models/Field"
import * as AsyncField from "@/helpers/AsyncField"
import { isBlank } from "@/utils"

export const getState = ({ product }) => product

export const getView = (state) => getState(state)._view.value

export const getProductId = (state) => getState(state).productId

export const getAuthDescriptor = (state) => getState(state).authDescriptor

export const getFormsEditable = (state) => getProduct(state).canEdit

export const getFormsExpanded = (state) => getState(state).formsExpanded

export const getForm = (state) => getView(state).form

export const getProduct = (state) => getView(state).product

export const getDefaultRateCategories = (state) => getProduct(state).defaultRateCategories

export const getDefaultTiers = (state) => getProduct(state).defaultTiers

export const getReferenceOptions = (state) => getView(state).referenceOptions

// TODO This can soon be removed in favour of +getForm+
export const getProductFilters = (state) => {
  const form = getForm(state)

  return {
    id: getProductId(state),
    bidId: form.bidId.value,
    contractPeriodId: form.contractPeriodId.value,
    detailsConfirmationId: form.detailsConfirmationId.value,
  }
}

export const getProductMainDetails = (state) => {
  const product = getProduct(state)

  return {
    name: product.name,
    code: product.code,
    port: { id: product.port.id, label: product.port.name },
    cruiseBrands: product.cruiseBrands.map((cruiseBrand) => ({
      id: cruiseBrand.id,
      label: cruiseBrand.name,
    })),
    incumbentOperator: product.incumbentCompany
      ? { id: product.incumbentCompany.id, label: product.incumbentCompany.name }
      : null,
  }
}

export const getSectionChat = (section) => (state) => {
  const form = getForm(state)
  const bidId = form.bidId.value
  const bid = getBid(bidId)(state)
  const product = getProduct(state)

  const bidChat = bid ? bid.chats.find((chat) => chat.section === section) : null
  const productChat = product ? product.chats.find((chat) => chat.section === section) : null

  return bidChat || productChat || null
}

export const isProductStale = (state) => AsyncField.isStale(getState(state)._view)

export const isProductReady = (state) => AsyncField.isReady(getState(state)._view)

export const isProductIdea = (state) => isBlank(getProduct(state).season.id)

export const getFeatures = (state) => getProduct(state).features

export const canEditProduct = (state) => getFeatures(state).general.product.editable

export const isOverriddenNetRatesheetHidden = (state) =>
  getFeatures(state).general.product.hideOverriddenNetRatesheet

export const isCalculatedNetRatesheetHidden = (state) =>
  getFeatures(state).general.product.hideCalculatedNetRatesheet

export const getBids = (state) => getProduct(state).bids

export const getBid = (bidId) => (state) => _.find(getBids(state), ({ id }) => id === bidId)

export const canEditBid = (bidId) => (state) => {
  const authDescriptor = getAuthDescriptor(state)
  const bid = getBid(bidId)(state)

  if (!bid) {
    return false
  }

  return (
    authDescriptor.isTourUi &&
    authDescriptor.companyId === bid.operator.id &&
    (bid.status === BidStatuses.REQUESTED || bid.status === BidStatuses.DRAFT)
  )
}

export const getActiveBid = (state) => {
  const editingProduct = canEditProduct(state)

  if (editingProduct) {
    return null
  }

  return getBid(getProductFilters(state).bidId)(state)
}

export const canEditActiveBid = (state) => {
  const activeBid = getActiveBid(state)

  if (!activeBid) {
    return false
  }

  return canEditBid(activeBid.id)(state)
}

export const canEdit = (state) => {
  const editProduct = canEditProduct(state)
  const editActiveBid = canEditActiveBid(state)

  return editProduct || editActiveBid
}

export const getFieldUpdateContext = (state) => _.omitBy(getProductFilters(state), _.isNil)

export const getMediaItems = (state) => getProduct(state).mediaItems

export const getOriginalMediaItems = (state) => getProduct(state).originalMediaItems

export const getTimeline = (state) => getProduct(state).segments

export const getOriginalStops = (state) => {
  const fieldSettings = getSettings(ReservedFields.TOUR_STOPS)(state)
  const originalTourStops = fieldSettings[ReservedFields.TOUR_STOPS]?.previousValue || []

  const { currency } = getProduct(state)

  return originalTourStops.map((stop) => ({
    components: [],
    ...stop,
    fieldName: originalTourStops.name,
    currency,
  }))
}

export const getOriginalStopComponents = (state) => {
  const originalStops = getOriginalStops(state)

  const stopsWithDefaultNames = originalStops.map((stop, stopIndex) => {
    const stopName = stop.name.trim() === "" ? `Segment ${stopIndex + 1}` : stop.name

    const updatedComponents = stop.components.map((component, componentIndex) => {
      const componentName =
        component.name.trim() === "" ? `Component ${componentIndex + 1}` : component.name
      return { ...component, name: componentName }
    })

    return { ...stop, name: stopName, components: updatedComponents }
  })

  return stopsWithDefaultNames
}

export const getStops = (state) => {
  const { currency } = getProduct(state)
  const field = getField(ReservedFields.TOUR_STOPS)(state)

  return field.value.map((stop) => ({
    components: [],
    ...stop,
    fieldName: field.name,
    currency,
  }))
}

export const getStopsWithComponents = (state) => {
  const stops = getStops(state)

  const stopsWithDefaultNames = stops.map((stop, stopIndex) => {
    const stopName = stop.name.trim() === "" ? `Segment ${stopIndex + 1}` : stop.name

    const updatedComponents = stop.components.map((component, componentIndex) => {
      const componentName =
        component.name.trim() === "" ? `Component ${componentIndex + 1}` : component.name
      return { ...component, name: componentName }
    })

    return { ...stop, name: stopName, components: updatedComponents }
  })

  return stopsWithDefaultNames
}

export const getSectionFieldNames = {
  financialDetails: (state) => {
    const { isTourUi } = getAuthDescriptor(state)

    return isTourUi
      ? [ReservedFields.NET_RATESHEET]
      : [
          ReservedFields.TARGET_ADULTS,
          ReservedFields.TARGET_CHILDREN,
          ReservedFields.TARGET_SALES,
          ReservedFields.TARGET_MARGIN,
          ReservedFields.RACK_RATESHEET,
          ReservedFields.NET_RATESHEET,
        ]
  },
  description: (state) => getProduct(state).descriptionFieldNames,
  tourDetails: (state) => getProduct(state).tourDetailsFieldNames,
  additionalDetails: (state) => getProduct(state).additionalDetailsFieldNames,
  images: () => [ReservedFields.TOUR_IMAGES],
  stops: () => [ReservedFields.TOUR_STOPS],
  tourLocation: () => [TourLocation.START_LOCATION, TourLocation.TOUR_DURATION],
  logistics: () => [ReservedFields.LOGISTICAL_COMPONENTS],
  overhead: () => [ReservedFields.OVERHEAD_CATEGORIES],
  priceVariance: () => [],
  extraFees: () => [ReservedFields.EXTRA_FEES],
}

export const getAllFieldNames = (state) => _.flatMap(getSectionFieldNames, (fn) => fn(state))

export const hasField = (fieldName) => (state) => _.includes(getAllFieldNames(state), fieldName)

export const getFinancialDetailFields = (state) => {
  const fieldNames = getSectionFieldNames.financialDetails(state)

  return getFieldValues(...fieldNames)(state)
}

export const getTargetPaxFieldNames = (state) => {
  // TODO Switch to dynamic pax types here
  const paxTypeFields = [ReservedFields.TARGET_ADULTS, ReservedFields.TARGET_CHILDREN]

  const financialFields = getSectionFieldNames.financialDetails(state)

  return paxTypeFields.filter((field) => _.includes(financialFields, field))
}

export const getTargetRevenueFieldNames = (state) => {
  const financialFields = getSectionFieldNames.financialDetails(state)

  return [ReservedFields.TARGET_SALES, ReservedFields.TARGET_MARGIN].filter((field) =>
    _.includes(financialFields, field)
  )
}

export const getProductField =
  (fieldName: string, filter = {}) =>
  (state) => {
    const { primaryDocument } = getProduct(state)

    return (
      _.find(primaryDocument.fields, ({ name }) => _.snakeCase(name) === _.snakeCase(fieldName)) ||
      nullField(fieldName)
    )
  }

export const getFields =
  (...fieldNames: string[]) =>
  (state): Record<string, any> =>
    _.reduce(
      fieldNames,
      (values, fieldName) => ({
        ...values,
        [fieldName]: getField(fieldName)(state),
      }),
      {}
    )

export const getFieldValues =
  (...fieldNames: string[]) =>
  (state): Record<string, any> =>
    _.reduce(
      fieldNames,
      (values, fieldName) => ({
        ...values,
        [fieldName]: getField(fieldName)(state).value,
      }),
      {}
    )

export const getFieldSettings =
  (...fieldNames: string[]) =>
  (state): Record<string, any> =>
    _.reduce(
      fieldNames,
      (values, fieldName) => ({
        ...values,
        [fieldName]: getField(fieldName)(state),
      }),
      {}
    )

export const getSettings =
  (...fieldNames: string[]) =>
  (state): Record<string, any> => {
    const fieldSettings = getView(state).product.fieldSettings.fields

    return _.reduce(
      fieldNames,
      (values, fieldName) => {
        const fieldSetting = _.find(fieldSettings, { name: fieldName })

        return {
          ...values,
          [fieldName]: fieldSetting || null,
        }
      },
      {}
    )
  }

export const countIncompleteRequiredFields =
  (...fieldNames: string[]) =>
  (state): number => {
    const fieldsSettings = getSettings(...fieldNames)(state)

    let count = 0

    Object.values(fieldsSettings).forEach((field) => {
      if (field?.required && field?.incomplete) {
        count += 1
      }
    })

    return count
  }

export const countChangedFields =
  (...fieldNames: string[]) =>
  (state): number => {
    const fieldsSettings = getSettings(...fieldNames)(state)
    let count = 0

    Object.values(fieldsSettings).forEach((field) => {
      if (field?.changed) {
        count += 1
      }
    })

    return count
  }

export const getPerformanceSummary = (state) => {
  const { dailySummary, seasonSummary } = getProduct(state)

  return {
    daily: dailySummary,
    season: seasonSummary,
  }
}

// TODO Apply filters to search for ship and cruise version
export const getBidField =
  (bidId: string, filter = {}) =>
  (fieldName: string) =>
  (state) => {
    const {
      document: { fields },
    } = getBid(bidId)(state)

    return (
      _.find(fields, ({ name }) => _.snakeCase(name) === _.snakeCase(fieldName)) ||
      nullField(fieldName)
    )
  }

export const getField = (fieldName: string) => (state) => {
  const filter = getProductFilters(state)
  const bid = getActiveBid(state)

  const productField = getProductField(fieldName, filter)(state)

  if (bid) {
    const bidField = getBidField(bid.id, filter)(fieldName)(state)

    // TODO Calling this the rootField might not be wise. I essentially want the field that
    //      I will be using to display the original product values when viewing this from
    //      the perspective of a bid.
    return {
      ...bidField,
      rootField: productField,
    }
  }

  return productField
}

export const getLogisticalComponents = (state) => {
  const { currency } = getProduct(state)
  const field = getField(ReservedFields.LOGISTICAL_COMPONENTS)(state)
  const {
    dailySummary: { expectedGuests },
  } = getProduct(state)

  return field.value.map((component) => ({
    ...component,
    usage: _.ceil(expectedGuests / (component.capacity || 0)) || 0,
    fieldName: field.name,
    currency,
  }))
}

export const getOverheadCategories = (state) => {
  const { currency } = getProduct(state)
  const field = getField(ReservedFields.OVERHEAD_CATEGORIES)(state)

  return field.value.map((category) => ({
    ...category,
    percentage: getBuyRatePercentage(
      category.ratesheet.rates.adult,
      category.ratesheet.rates.child
    )(state),
    fieldName: field.name,
    currency,
  }))
}

export const getOriginalOverheadCategories = (state) => {
  const { currency } = getProduct(state)
  const fieldSettings = getSettings(ReservedFields.OVERHEAD_CATEGORIES)(state)
  const originalOverheadCategories =
    fieldSettings[ReservedFields.OVERHEAD_CATEGORIES]?.previousValue || []
  return originalOverheadCategories.map((category) => ({
    ...category,
    percentage: getBuyRatePercentage(
      category.ratesheet.rates.adult,
      category.ratesheet.rates.child
    )(state),
    fieldName: originalOverheadCategories.name,
    currency,
  }))
}

// TODO This should eventually check the ratesheet type and use target pax to get rates
export const getTargetBuyRates = (state) => {
  const {
    dailySummary: { currentCosts },
  } = getProduct(state)
  const { target_adults, target_children } = getFieldValues(
    ReservedFields.TARGET_ADULTS,
    ReservedFields.TARGET_CHILDREN
  )(state)

  const total = target_adults + target_children

  if (total === 0) {
    return {
      adult: 0,
      child: 0,
    }
  }

  // TODO Taking the ratio here is technically not right, but this becomes very complicated
  // with tiered rates
  return {
    adult: currentCosts.fractional / total,
    child: currentCosts.fractional / total,
  }
}

export const getBuyRatePercentage = (adultRate, childRate) => (state) => {
  const {
    dailySummary: { currentCosts },
  } = getProduct(state)
  const { target_adults, target_children } = getFieldValues(
    ReservedFields.TARGET_ADULTS,
    ReservedFields.TARGET_CHILDREN
  )(state)

  const cost = adultRate * target_adults + childRate * target_children

  return cost / currentCosts.fractional || 0
}

export const getVehicles = (state) => {
  const { value } = getField(ReservedFields.VEHICLES)(state)

  return value
}

export const getComments = (sectionName) => (state) => {
  const { sectionComments } = getProduct(state)

  return _.filter(sectionComments, (comment) => comment.sectionName === sectionName)
}

export const getProductCurrency = (state) => getProduct(state).currency

export const getSettlementMethod = (state) =>
  getField(ReservedFields.SETTLEMENT_METHOD)(state).value

export const isVehicleBasedSettlement = (state) => {
  const settlementMethod = getSettlementMethod(state)

  return (
    settlementMethod === SettlementMethods.PER_VEHICLE ||
    settlementMethod === SettlementMethods.PER_VEHICLE_AVERAGE
  )
}

export const getOptionsForReferenceType = (type: string) => (state) =>
  getReferenceOptions(state)[type]
