import React from "react"
import { Table } from "react-bootstrap"
import _ from "lodash"

const displayRate = (rate) => (parseInt(rate, 10) / 100.0).toFixed(2)

const FlatRatesheetView = ({ value, previousValues = false }) => {
  const textStyle = previousValues ? "original-product-text" : ""

  return (
    <Table>
      <thead className="mb-2">
        <tr>
          <th className={textStyle}>Category</th>
          <th className={`text-right ${textStyle} `}>Rate</th>
        </tr>
      </thead>
      <tbody>
        {_.map(value.rates, (rate, ctg) => (
          <tr key={ctg}>
            <td className={textStyle}>{_.capitalize(ctg)}</td>
            <td className={`text-right ${textStyle} `}>{displayRate(rate)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default FlatRatesheetView
