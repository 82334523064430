import React, { FC, useState } from "react"
import { useSelector } from "react-redux"

import { getSectionFieldNames, getSettings } from "@/onboarding/features/product/selectors"
import FieldTable from "@/onboarding/features/product/components/shared/FieldTable"

const Details: FC = () => {
  const fieldNames = useSelector(getSectionFieldNames.tourLocation)
  const fieldsSettings = useSelector(getSettings(...fieldNames))
  const hasChangedFields = fieldNames.some((fieldName) => fieldsSettings[fieldName]?.changed)
  const labelStyle = hasChangedFields ? "yellow-underline" : ""

  const [showPrevious, setShowPrevious] = useState(false)

  const toggleShowPrevious = () => {
    if (hasChangedFields) {
      setShowPrevious((prev) => !prev)
    }
  }

  return (
    <>
      <h5 className={`mt-1 mb-1 ${labelStyle}`} onClick={toggleShowPrevious}>
        Overview
      </h5>
      {showPrevious && (
        <small className="ms-2 text-muted fst-italic">Showing Original Values</small>
      )}
      <FieldTable fieldNames={fieldNames} showPrevious={showPrevious} />
    </>
  )
}

export default Details
