import React, { FC } from "react"
import { useSelector } from "react-redux"
import { getSectionChat, getSettings } from "@/onboarding/features/product/selectors"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import _ from "lodash"
import SectionHolder from "./shared/SectionHolder"
import Content from "./headsets/Content"
import Edit from "./headsets/Edit"

const Headsets: FC = () => {
  const { headset_components } = useSelector(getSettings(ReservedFields.HEADSET_COMPONENTS))

  const sectionChat = useSelector(getSectionChat(_.snakeCase("headsetComponents")))

  const isWriteable = headset_components?.writeable ?? false
  const headsetIsChanged = headset_components?.changed ?? false

  const rateSettings = useSelector(getSettings(ReservedFields.HEADSET_FEE_RATESHEET))
  const rateIsChanged = rateSettings[ReservedFields.HEADSET_FEE_RATESHEET]?.changed

  const changedComponent = rateIsChanged || headsetIsChanged

  return (
    <SectionHolder
      title="Headsets"
      editComponent={isWriteable ? Edit : null}
      editMethod="inline"
      showLockedIcon={!isWriteable}
      changedComponent={changedComponent}
      chat={sectionChat}
    >
      <Content changedComponent={changedComponent} />
    </SectionHolder>
  )
}

export default Headsets
