import React, { useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"

import BaseComponent, { ComponentType } from "@/onboarding/features/product/models/Component"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import { removeFieldListItem, updateFieldListItem } from "@/onboarding/features/product/Slice"
import LoadingSpinner from "@/components/LoadingSpinner"
import { useProductSubmitting } from "@/onboarding/features/product/helpers"
import { getFormsEditable } from "@/onboarding/features/product/selectors"

import SimpleComponentView from "./Simple"
import TransportComponentView from "./Transport"
import VenueComponentView from "./Venue"
import RefreshmentComponentView from "./Refreshment"
import RestaurantComponentView from "./Restaurant"
import EntertainmentComponentView from "./Entertainment"
import GuideComponentView from "./Guide"
import AttachmentComponentView from "./Attachment"

export const getView = (component: BaseComponent) => {
  switch (component.kind) {
    case ComponentType.SIMPLE:
      return SimpleComponentView
    case ComponentType.TRANSPORT:
      return TransportComponentView
    case ComponentType.VENUE:
      return VenueComponentView
    case ComponentType.REFRESHMENT:
      return RefreshmentComponentView
    case ComponentType.RESTAURANT:
      return RestaurantComponentView
    case ComponentType.ENTERTAINMENT:
      return EntertainmentComponentView
    case ComponentType.GUIDE:
      return GuideComponentView
    case ComponentType.ATTACHMENT:
      return AttachmentComponentView
    default:
      throw new Error(`Unknown component kind: ${component.kind}`)
  }
}

const EditWrapper = ({
  fieldName,
  index,
  component,
  editable = true,
  originalValues = false,
}: {
  fieldName: ReservedFields
  index: number
  component: BaseComponent
  editable: boolean
  originalValues: boolean
}): JSX.Element => {
  const dispatch = useDispatch()
  const formsEditable = useSelector(getFormsEditable)
  const [editing, setEditing] = useState(formsEditable)
  const [isSubmitting, setSubmitting] = useProductSubmitting()

  useEffect(() => {
    if (editing !== formsEditable) {
      setEditing(formsEditable)
    }
  }, [formsEditable])

  const View = getView(component)

  return (
    <div className="d-flex gap-2 mb-2">
      <div style={{ width: "20px" }}>{`${index + 1}.`}</div>
      <div className="flex-grow-1">
        <Formik
          initialValues={component}
          onSubmit={(values) => {
            setSubmitting(true)
            dispatch(updateFieldListItem({ fieldName, index, value: values }))
          }}
        >
          {({ handleSubmit, dirty }) => (
            <Form className="d-flex flex-column gap-2" onSubmit={handleSubmit}>
              <View
                component={component}
                previousValues={originalValues}
                editing={editing}
                hideRates={false}
              />
              {editing && dirty && (
                <div className="d-flex gap-2">
                  <Button size="sm" type="submit" disabled={isSubmitting}>
                    <i className="fas fa-save me-2" />
                    Save
                  </Button>
                  {isSubmitting && <LoadingSpinner />}
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
      <div className="d-flex flex-column gap-2">
        {editing ? (
          <Button
            size="sm"
            variant="outline-primary"
            disabled={isSubmitting}
            className="text-start"
            onClick={() => setEditing(false)}
          >
            <div key="cancel">
              <i className="fas fa-times me-2" />
              Cancel
            </div>
          </Button>
        ) : (
          editable && (
            <Button
              size="sm"
              variant="outline-primary"
              disabled={isSubmitting}
              className="text-start"
              onClick={() => setEditing(true)}
            >
              <div key="edit">
                <i className="fas fa-edit me-2" />
                Edit
              </div>
            </Button>
          )
        )}
        {editable && (
          <Button
            size="sm"
            variant="outline-danger"
            disabled={isSubmitting}
            onClick={() => {
              setSubmitting(true)
              dispatch(
                removeFieldListItem({
                  fieldName,
                  index,
                })
              )
            }}
          >
            <i className="fas fa-trash me-2" />
            Remove
          </Button>
        )}
      </div>
    </div>
  )
}

export default EditWrapper
