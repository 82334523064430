import React from "react"

/*
  Note that the keyed div's are to prevent a DOM removal issue when the
  parent value updates.
  See https://stackoverflow.com/questions/42956884/failed-to-execute-removechild-on-node
*/

const IconWrapper = ({ name, className }: { name?: string }) => {
  const keyName = name || className.replace(/[\W_]+/g, "-")
  const iconClassName = className

  return ({ title, className = "d-inline-block" }: { title?: string }): JSX.Element => (
    <div key={keyName} className={className}>
      <span className={iconClassName} title={title} />
    </div>
  )
}

export const GreenCheckMark = IconWrapper({
  className: "fas fa-check-circle text-success",
})

export const YellowExchange = IconWrapper({
  className: "fas fa-exchange-alt text-secondary",
})

export const SignedContract = IconWrapper({
  className: "fas fa-file-signature text-warning",
})

export const MutedcheckMark = IconWrapper({
  className: "fas fa-check-circle text-muted",
})

export const RedCross = IconWrapper({
  className: "fas fa-times-circle text-danger",
})

export const OrangeQuestion = IconWrapper({
  className: "fas fa-question-circle text-warning",
})

export const OrangeExclamation = IconWrapper({
  className: "fas fa-exclamation-triangle text-warning",
})

export const StatusIcon = ({ value }: { value: boolean }): JSX.Element =>
  value ? <GreenCheckMark /> : <RedCross />

export const CaretDown = IconWrapper({
  className: "fas fa-caret-down",
})

export const CaretUp = IconWrapper({
  className: "fas fa-caret-up",
})

export const BlueLock = IconWrapper({
  className: "fas fa-lock",
})

export const UserCircle = IconWrapper({
  className: "fas fa-user-circle",
})

export const InfoCircle = IconWrapper({
  className: "fas fa-info-circle",
})
