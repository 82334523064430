import React, { FC } from "react"
import { useSelector } from "react-redux"

import { ReservedFields } from "@/onboarding/features/product/models/Product"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import {
  canEdit,
  countChangedFields,
  getSectionChat,
  getSettings,
} from "@/onboarding/features/product/selectors"

import _ from "lodash"
import SectionHolder from "./shared/SectionHolder"
import SettlementMethod from "./settlement/SettlementMethod"
import RatesDisplay from "./settlement/RatesDisplay"
import VehicleDisplay from "./settlement/VehicleDisplay"

// TODO(auth): We need to prevent this data from ever reaching the frontend instead of just
//  hiding it.
const Settlement: FC = () => {
  const { settlement_method } = useSelector(getSettings(ReservedFields.SETTLEMENT_METHOD))
  const proposedChangesCount = useSelector(countChangedFields(ReservedFields.SETTLEMENT_METHOD))
  const sectionChat = useSelector(getSectionChat(_.snakeCase("settlement")))
  const editable = useSelector(canEdit)

  return (
    <SectionHolder
      title="Settlement"
      showLockedIcon={!settlement_method.writeable}
      chat={sectionChat}
      proposedChangesCount={proposedChangesCount}
    >
      <div className="d-flex flex-column gap-2">
        <div className="d-flex justify-content-between gap-4">
          <SettlementMethod />
          <CruiseLineOnly>
            <RatesDisplay
              fieldName={ReservedFields.RACK_RATESHEET}
              rateLabel="Sell Rate"
              editable={editable}
            />
          </CruiseLineOnly>
        </div>
        <VehicleDisplay />
      </div>
    </SectionHolder>
  )
}
export default Settlement
