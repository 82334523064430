import React, { FC } from "react"
import { Col, Row, Table } from "react-bootstrap"

import FormikBootstrap from "@/components/formik-bootstrap"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import Pricing from "@/pricing"

const WriteView: FC = () => (
  <Row>
    <Col md={3}>
      <FormikBootstrap.TextInput name="name" label="Name" />
    </Col>
    <Col>
      <FormikBootstrap.TextInput name="description" label="Description" />
    </Col>
  </Row>
)

const ReadView = ({ component, hideRates }): JSX.Element => (
  <div className="d-flex flex-column gap-2">
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td aria-label="Name">
            <TextDisplay value={component.name} />
          </td>
          <td aria-label="Description">
            <TextDisplay value={component.description} />
          </td>
        </tr>
      </tbody>
    </Table>
    {!hideRates && (
      <div>
        <h2 className="h5">Rates</h2>
        <Pricing.View value={component.ratesheet} />
      </div>
    )}
  </div>
)

const View = ({
  component,
  previousValues = false,
  editing = false,
  hideRates = false,
}): JSX.Element =>
  editing ? <WriteView /> : <ReadView component={component} hideRates={hideRates} />

export default View
