/* eslint-disable import/prefer-default-export */
import React from "react"
import { useSelector } from "react-redux"
import _ from "lodash"

import Pricing from "@/pricing"
import { getDefaultRateCategories } from "@/onboarding/features/product/selectors"
import RateDisplay from "@/onboarding/features/product/components/shared/RateDisplay"

export const HeaderRow = ({ children, editIcon = undefined, showPrevious = false }) => {
  const rateCategories = useSelector(getDefaultRateCategories)
  const textStyle = showPrevious ? "original-product-text" : ""

  if (editIcon) {
    return (
      <tr>
        {children}
        {rateCategories.map((category, index) =>
          index + 1 === rateCategories.length ? (
            <th key={index} className={`w-25 ${textStyle}`}>
              <div className="d-flex justify-content-end gap-2">
                <span>{category}</span>
                {editIcon}
              </div>
            </th>
          ) : (
            <th key={index} className={`text-right w-25 ${textStyle}`}>
              {category}
            </th>
          )
        )}
      </tr>
    )
  }

  return (
    <tr>
      {children}
      {rateCategories.map((category) => (
        <th key={category} className={`text-right w-25 ${textStyle}`}>
          {category}
        </th>
      ))}
    </tr>
  )
}

const FlatRatesheetRow = ({ children, ratesheet, showPrevious }): JSX.Element => {
  const rateCategories = useSelector(getDefaultRateCategories)
  const textStyle = showPrevious ? "original-product-text" : ""

  return (
    <tr>
      {children}
      {rateCategories.map((category) => (
        <td key={category} className={`text-right w-25 ${textStyle}`}>
          <RateDisplay amount={ratesheet.rates[category]} showPrevious={showPrevious} />
        </td>
      ))}
    </tr>
  )
}

const calculateTierBands = (tiers) => {
  const labels = _.map(tiers, "tierFrom")

  return labels.map((tierFrom, index) => {
    if (index === labels.length - 1) {
      return `${tierFrom}+`
    }

    const tierStart = parseInt(tierFrom, 10)
    const tierEnd = parseInt(labels[index + 1], 10) - 1

    if (tierStart === tierEnd) {
      return tierStart
    }

    return `${tierStart} - ${tierEnd}`
  })
}

const TieredRatesheetRow = ({ children, ratesheet, showPrevious = false }): JSX.Element => {
  const rateCategories = useSelector(getDefaultRateCategories)
  const tierLabels = calculateTierBands(ratesheet.tiers)
  const textStyle = showPrevious ? "original-product-text" : ""

  return (
    <tr>
      {children}
      {rateCategories.map((category) => (
        <td key={category} className="w-25">
          <div className="d-flex flex-column gap-1">
            {ratesheet.tiers.map((tier, index) => (
              <div key={tier.tierFrom} className="d-flex justify-content-between gap-1">
                <span className={textStyle}>{tierLabels[index]}</span>
                <span>
                  <RateDisplay amount={tier.rates[category]} showPrevious={showPrevious} />
                </span>
              </div>
            ))}
          </div>
        </td>
      ))}
    </tr>
  )
}

const BulkRatesheetRow = ({ children, ratesheet, showPrevious = false }): JSX.Element => {
  const rateCategories = useSelector(getDefaultRateCategories)
  const tierLabels = calculateTierBands(ratesheet.tiers)
  const textStyle = showPrevious ? "original-product-text" : ""

  return (
    <tr>
      {children}
      {rateCategories.map((category) => (
        <td key={category} className="w-25">
          <div className="d-flex flex-column gap-1">
            {ratesheet.tiers.map((tier, index) => (
              <div key={tier.tierFrom} className="d-flex justify-content-between gap-1">
                <span className={textStyle}>{tierLabels[index]}</span>
                <span>
                  <RateDisplay
                    amount={ratesheet.bulkRate / tier.tierFrom}
                    showPrevious={showPrevious}
                  />
                </span>
              </div>
            ))}
          </div>
        </td>
      ))}
    </tr>
  )
}

export const Row = ({ children, ratesheet, showPrevious = false }) => {
  switch (ratesheet.kind) {
    case Pricing.types.SelectableRatesheet:
      return (
        <Row ratesheet={ratesheet.ratesheet} showPrevious={showPrevious}>
          {children}
        </Row>
      )
    case Pricing.types.FlatRatesheet:
      return (
        <FlatRatesheetRow ratesheet={ratesheet} showPrevious={showPrevious}>
          {children}
        </FlatRatesheetRow>
      )
    case Pricing.types.TieredRatesheet:
      return (
        <TieredRatesheetRow ratesheet={ratesheet} showPrevious={showPrevious}>
          {children}
        </TieredRatesheetRow>
      )
    case Pricing.types.BulkRatesheet:
      return (
        <BulkRatesheetRow ratesheet={ratesheet} showPrevious={showPrevious}>
          {children}
        </BulkRatesheetRow>
      )
    default:
      return (
        <tr>
          {children}
          <td>
            <span className="text-danger">Unsupported Ratesheet</span>
          </td>
        </tr>
      )
  }
}
