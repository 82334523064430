import React, { FC, useState } from "react"
import { Button, Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import {
  getOverheadCategories,
  getField,
  canEdit,
  getSettings,
  getOriginalOverheadCategories,
} from "@/onboarding/features/product/selectors"
import { formatPercentage } from "@/helpers/number"
import { useModal } from "@/helpers/useModal"
import EditIcon from "@/onboarding/features/product/components/shared/EditIcon"
import EditModal from "@/onboarding/features/product/components/shared/EditModal"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"

import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import _ from "lodash"
import * as RateTable from "./RateTable"
import OverheadEdit from "./OverheadEdit"

const OverheadTable: FC = () => {
  const field = useSelector(getField(ReservedFields.OVERHEAD_CATEGORIES))
  const operatorTitle = field.required ? "Overhead Category *" : "Overhead Category"
  const { overhead_components } = useSelector(getSettings(ReservedFields.OVERHEAD_CATEGORIES))
  const allCategories = useSelector(getOverheadCategories)
  const originalCategories = useSelector(getOriginalOverheadCategories)
  const editable = useSelector(canEdit)

  const [showPrevious, setShowPrevious] = useState(false)
  const handleToggle = () => setShowPrevious(!showPrevious)

  const toggleModal = useModal((props) => (
    <EditModal {...props} title="Overhead Categories" Body={OverheadEdit} />
  ))

  const textStyle = showPrevious ? "original-product-text" : ""

  const categories = showPrevious ? originalCategories : allCategories
  return (
    <div>
      <div className="d-flex justify-content-between mb-2">
        <h5 className={textStyle}>Overhead Category</h5>
        {overhead_components.changed && (
          <Button size="sm" variant="outline-secondary" onClick={handleToggle}>
            {showPrevious ? "View Updated" : "View Original"}
          </Button>
        )}
      </div>
      {showPrevious && (
        <small className="ms-2 mb-2 text-muted fst-italic">Showing Original Values</small>
      )}
      {_.isEmpty(categories) && <div className={textStyle}>No overhead categories loaded</div>}
      {!_.isEmpty(categories) && (
        <Table>
          <thead>
            <RateTable.HeaderRow
              showPrevious={showPrevious}
              editIcon={field.writeable && editable && <EditIcon onEdit={toggleModal} />}
            >
              <OperatorOnly>
                <th>{operatorTitle}</th>
              </OperatorOnly>
              <CruiseLineOnly>
                <th className={textStyle}>Overhead Category</th>
              </CruiseLineOnly>
              <th className={textStyle}>Description</th>
            </RateTable.HeaderRow>
          </thead>
          <tbody>
            {categories.map((category, index) => (
              <RateTable.Row key={index} ratesheet={category.ratesheet} showPrevious={showPrevious}>
                <td>
                  <div className="d-flex gap-2">
                    <span className="ps-2">
                      <TextDisplay value={category.name} previousValue={showPrevious} />
                    </span>
                    <span className={textStyle}>{`${formatPercentage(category.percentage)}`}</span>
                  </div>
                </td>
                <td className={textStyle}>{category.description}</td>
              </RateTable.Row>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  )
}

export default OverheadTable
