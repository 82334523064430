import React, { useState } from "react"
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap"
import { useSelector } from "react-redux"

import {
  getProductField,
  getSectionFieldNames,
  getSettings,
} from "@/onboarding/features/product/selectors"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"

const DisplayValue = ({
  name,
  label,
  requiredField = false,
  incompleteField = false,
}): JSX.Element => {
  const { value } = useSelector(getProductField(name))

  return (
    <div className="d-flex flex-column gap-1">
      <span>
        <b>
          {label}
          <OperatorOnly>
            {requiredField && incompleteField && (
              <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip-incomplete-${name}`}>
                    This is an incomplete required field
                  </Tooltip>
                }
                placement="top"
              >
                <span className="ms-1" style={{ color: "red" }}>
                  *
                </span>
              </OverlayTrigger>
            )}
          </OperatorOnly>
        </b>
      </span>
      <TextDisplay value={value} />
    </div>
  )
}

const DisplayChangedValue = ({
  name,
  label,
  previousValue,
  requiredField = false,
  incompleteField = false,
}): JSX.Element => {
  const { value } = useSelector(getProductField(name))
  const [showPrevious, setShowPrevious] = useState(false)

  const handleToggle = () => setShowPrevious(!showPrevious)

  return (
    <div className="d-flex flex-column gap-1 cursor-pointer">
      <div
        className="d-flex align-items-center"
        onClick={handleToggle}
        style={{ cursor: "pointer" }}
      >
        <b className="yellow-underline">
          {label}
          <OperatorOnly>
            {requiredField && incompleteField && (
              <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip-incomplete-${name}`}>
                    This is an incomplete required field
                  </Tooltip>
                }
                placement="top"
              >
                <span className="ms-1" style={{ color: "red" }}>
                  *
                </span>
              </OverlayTrigger>
            )}
          </OperatorOnly>
        </b>
        <small className="ms-2 text-muted fst-italic">{showPrevious ? "Original" : ""}</small>
      </div>
      {showPrevious ? (
        <TextDisplay value={previousValue} previousValue />
      ) : (
        <TextDisplay value={value} />
      )}
    </div>
  )
}

const Content = (): JSX.Element => {
  const fieldNames = useSelector(getSectionFieldNames.description)
  const fieldsSettings = useSelector(getSettings(...fieldNames))

  const renderField = (name, label) => {
    const changedField = fieldsSettings[name]?.changed
    const previousValue = fieldsSettings[name]?.previousValue

    return changedField ? (
      <DisplayChangedValue
        key={name}
        name={name}
        label={label}
        requiredField={fieldsSettings[name]?.required}
        incompleteField={fieldsSettings[name]?.incomplete}
        previousValue={previousValue}
      />
    ) : (
      <DisplayValue
        key={name}
        name={name}
        label={label}
        requiredField={fieldsSettings[name]?.required}
        incompleteField={fieldsSettings[name]?.incomplete}
      />
    )
  }

  return (
    <div className="d-flex flex-column gap-3">
      {renderField(ReservedFields.OVERVIEW, "Overview")}
      <Row>
        <Col>{renderField(ReservedFields.HIGHLIGHTS, "Highlights")}</Col>
        <Col>{renderField(ReservedFields.IMPORTANT_NOTES, "Important Notes")}</Col>
      </Row>
      <Row>
        <Col>{renderField(ReservedFields.WHAT_TO_BRING, "What to Bring")}</Col>
        <Col>{renderField(ReservedFields.WHAT_TO_WEAR, "What to Wear")}</Col>
      </Row>
    </div>
  )
}

export default Content
