import React, { FC } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { useSelector } from "react-redux"

import { getProduct } from "@/onboarding/features/product/selectors"
import { isBlank } from "@/utils"

const InfoIcon: FC = () => (
  <span>
    <i className="fas fa-info-circle" />
  </span>
)

const ConfirmedDetails = ({ details }): JSX.Element => {
  if (isBlank(details.repliedBy)) {
    return <span className="text-success">Confirmed</span>
  }

  const description = `Confirmed by ${details.repliedBy} (${details.repliedAt})`

  return (
    <div className="d-inline-flex gap-1">
      <OverlayTrigger placement="bottom" overlay={<Tooltip>{description}</Tooltip>}>
        <span className="text-success">Confirmed</span>
      </OverlayTrigger>
      <InfoIcon />
    </div>
  )
}

const RejectedDetails = ({ details }): JSX.Element => {
  if (isBlank(details.repliedBy)) {
    return <span className="text-danger">Proposed Changes Awaiting Approval</span>
  }

  const description = `${details.reason} - Proposed by ${details.repliedBy} (${details.repliedAt})`

  return (
    <div className="d-inline-flex cursor-pointer gap-1">
      <OverlayTrigger overlay={<Tooltip>{description}</Tooltip>}>
        <span className="text-danger">Proposed Changes Awaiting Approval</span>
      </OverlayTrigger>
      <InfoIcon />
    </div>
  )
}

const DetailsConfirmationStatus: FC = () => {
  const { detailsConfirmation } = useSelector(getProduct)

  switch (detailsConfirmation.status) {
    case "confirmed":
      return <ConfirmedDetails details={detailsConfirmation} />
    case "pending":
      return <span className="text-warning">Pending</span>
    case "rejected":
      return <RejectedDetails details={detailsConfirmation} />
    default:
      return null
  }
}

export default DetailsConfirmationStatus
