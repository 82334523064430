import React, { FC } from "react"
import { Col, Row, Table } from "react-bootstrap"

import FormikBootstrap from "@/components/formik-bootstrap"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { isBlank } from "@/utils"
import Routes from "@/routes"

const WriteView: FC = () => (
  <Row>
    <Col md={3}>
      <FormikBootstrap.TextInput name="name" label="Name" />
    </Col>
    <Col md={6}>
      <FormikBootstrap.TextInput name="description" label="Description" />
    </Col>
    <Col md={3}>
      <FormikBootstrap.FileInput name="fileSrc" label="File" />
    </Col>
  </Row>
)

const ReadView = ({ component }): JSX.Element => (
  <div className="d-flex flex-column gap-2">
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>File</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td aria-label="Name">
            <TextDisplay value={component.name} />
          </td>
          <td aria-label="Description">
            <TextDisplay value={component.description} />
          </td>
          <td aria-label="Same Menu">
            {isBlank(component.fileSrc) ? (
              <span className="text-danger">N/A</span>
            ) : (
              <a
                href={Routes.file_holder_path({ id: component.fileSrc })}
                download
                className="text-underline"
              >
                Download
              </a>
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  </div>
)

const View = ({ component, previousValues = false, editing = false }): JSX.Element =>
  editing ? <WriteView /> : <ReadView component={component} />

export default View
