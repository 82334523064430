import React, { FC } from "react"
import { Col, Row, Table } from "react-bootstrap"

import FormikBootstrap from "@/components/formik-bootstrap"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import Pricing from "@/pricing"
import { isBlank } from "@/utils"
import Routes from "@/routes"
import RateInputs from "../../shared/RateInputs"

const WriteView: FC = () => (
  <div className="d-flex flex-column gap-2">
    <Row>
      <Col md={8}>
        <h5>Edit Details</h5>

        <Row className="mb-2">
          <Col md={3}>
            <FormikBootstrap.TextInput name="name" label="Name" />
          </Col>
          <Col md={3}>
            <FormikBootstrap.NumberInput name="capacity" label="Capacity" />
          </Col>
          <Col md={3}>
            <FormikBootstrap.FileInput name="menuSrc" label="Sample Menu" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={9}>
            <FormikBootstrap.TextInput name="description" label="Menu Description" />
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <FormikBootstrap.Checkbox name="restroomsAvailable" label="Restrooms Available?" />
          </Col>
          <Col md={4}>
            <FormikBootstrap.Checkbox name="liftsAvailable" label="Wheelchair Lifts Available?" />
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <FormikBootstrap.Checkbox name="airConditioned" label="Air Conditioned?" />
          </Col>
          <Col md={4}>
            <FormikBootstrap.Checkbox name="rampsAvailable" label="Wheelchair Ramps Available?" />
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <FormikBootstrap.Checkbox name="buffetService" label="Buffet Service?" />
          </Col>
          <Col md={4}>
            <FormikBootstrap.Checkbox name="wheelchairAccessible" label="Wheelchair Accessible?" />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormikBootstrap.Checkbox name="waiterService" label="Waiter Service?" />
          </Col>

          <Col md={4}>
            <FormikBootstrap.Checkbox
              name="specialDietaryMeals"
              label="Special Dietary Meals Offered By Request?"
            />
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <FormikBootstrap.Checkbox name="vegetarianMeals" label="Vegetarian Meals Offered?" />
          </Col>
        </Row>
      </Col>

      <Col md={4} className="ms-n4">
        <h5>Edit Rates</h5>
        <RateInputs name="ratesheet" />
      </Col>
    </Row>
  </div>
)

const ReadView = ({ component, hideRates, previousValues = false }): JSX.Element => {
  const textStyle = previousValues ? "original-product-text" : ""

  return (
    <div className="d-flex flex-column gap-2">
      <h5 className={textStyle}>Details</h5>
      <Table>
        <thead>
          <tr>
            <th className={textStyle}>Name</th>
            <th className={textStyle}>Description</th>
            <th className={textStyle}>Capacity</th>
            <th className={textStyle}>Sample Menu</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td aria-label="Name">
              <TextDisplay value={component.name} previousValue={previousValues} />
            </td>
            <td aria-label="Description">
              <TextDisplay value={component.description} previousValue={previousValues} />
            </td>
            <td aria-label="Capacity">
              <TextDisplay value={component.capacity} previousValue={previousValues} />
            </td>
            <td aria-label="Sample Menu">
              {isBlank(component.menuSrc) ? (
                <>N/A</>
              ) : (
                <a
                  href={Routes.file_holder_path({ id: component.menuSrc })}
                  download
                  className="text-underline"
                >
                  Download
                </a>
              )}
            </td>
          </tr>
        </tbody>
        <thead>
          <tr className="text-wrap">
            <th className={textStyle}>Air Conditioned?</th>
            <th className={textStyle}>Waiter Service?</th>
            <th className={textStyle}>Buffet Service?</th>
            <th className={textStyle}>Special Dietary Meals Offered By Request?</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td aria-label="Air Conditioned?">
              <TextDisplay value={component.airConditioned} previousValue={previousValues} />
            </td>
            <td aria-label="Waiter Service?">
              <TextDisplay value={component.waiterService} previousValue={previousValues} />
            </td>
            <td aria-label="Buffet Service?">
              <TextDisplay value={component.buffetService} previousValue={previousValues} />
            </td>
            <td aria-label="Special Dietary Meals Offered By Request?">
              <TextDisplay value={component.specialDietaryMeals} previousValue={previousValues} />
            </td>
          </tr>
        </tbody>
        <thead>
          <tr className="text-wrap">
            <th className={textStyle}>Vegetarian Meals Offered?</th>
            <th className={textStyle}>Wheelchair Accessible?</th>
            <th className={textStyle}>Wheelchair Ramps Available?</th>
            <th className={textStyle}>Wheelchair Lifts Available?</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td aria-label="Vegetarian Meals Offered?">
              <TextDisplay value={component.vegetarianMeals} previousValue={previousValues} />
            </td>
            <td aria-label="Wheelchair Accessible?">
              <TextDisplay value={component.wheelchairAccessible} previousValue={previousValues} />
            </td>
            <td aria-label="Wheelchair Ramps Available?">
              <TextDisplay value={component.rampsAvailable} previousValue={previousValues} />
            </td>
            <td aria-label="Wheelchair Lifts Available?">
              <TextDisplay value={component.liftsAvailable} previousValue={previousValues} />
            </td>
          </tr>
        </tbody>
        <thead>
          <tr className="text-wrap">
            <th colSpan={4} className={textStyle}>
              Restrooms Available?
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={4} aria-label="Restrooms Available?">
              <TextDisplay value={component.restroomsAvailable} previousValue={previousValues} />
            </td>
          </tr>
        </tbody>
      </Table>
      {!hideRates && (
        <Col md={3}>
          <h5 className={`mt-2 ${textStyle}`}>Rates</h5>
          <Pricing.View value={component.ratesheet} previousValues={previousValues} />
        </Col>
      )}
    </div>
  )
}

const View = ({
  component,
  previousValues = false,
  editing = false,
  hideRates = false,
}): JSX.Element =>
  editing ? (
    <WriteView />
  ) : (
    <ReadView component={component} hideRates={hideRates} previousValues={previousValues} />
  )

export default View
