import React, { useState, useEffect } from "react"
import { Button, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { getField, getProductField, getSettings } from "@/onboarding/features/product/selectors"
import { useModal } from "@/helpers/useModal"
import EditIcon from "@/onboarding/features/product/components/shared/EditIcon"
import EditModal from "@/onboarding/features/product/components/shared/EditModal"
import FormikFieldSubmitter from "@/onboarding/features/product/components/shared/FormikFieldSubmitter"
import RateInputs from "@/onboarding/features/product/components/shared/RateInputs"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import { updateFields } from "@/onboarding/features/product/Slice"

import _ from "lodash"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import * as RateTable from "./RateTable"

const Edit = ({ fieldName }: { fieldName: string }): JSX.Element => {
  const field = useSelector(getField(fieldName))

  return (
    <FormikFieldSubmitter initialValues={{ [fieldName]: field.value }}>
      <RateInputs name={fieldName} />
    </FormikFieldSubmitter>
  )
}

const RatesheetRow = ({
  fieldName,
  rowLabel,
  showRateSelectionButton = false,
  editable = true,
  showPreviousRate = false,
  showToggleButton = false,
}: {
  fieldName: string
  rowLabel: string
  showRateSelectionButton?: boolean
  editable?: boolean
  showPreviousRate?: boolean
  showToggleButton?: boolean
}): JSX.Element => {
  const dispatch = useDispatch()
  const { value: ratesheet } = useSelector(getProductField(fieldName))
  const { value: selectedRatesheetField } = useSelector(
    getProductField(ReservedFields.SELECTED_NET_RATESHEET_FIELD)
  )
  const fieldSettings = useSelector(getField(fieldName))
  const operatorLabel = fieldSettings.required ? `${rowLabel} *` : rowLabel

  const rateSettings = useSelector(getSettings(fieldName))
  const previousRate = rateSettings[fieldName]?.previousValue || []
  const [showPrevious, setShowPrevious] = useState(showPreviousRate)

  const handleToggle = () => {
    if (showToggleButton) {
      setShowPrevious((prev) => !prev)
    }
  }

  useEffect(() => {
    if (!showToggleButton) {
      setShowPrevious(showPreviousRate)
    }
  }, [showToggleButton, showPreviousRate])

  const toggleModal =
    fieldSettings.writeable &&
    useModal((props) => (
      <EditModal {...props} title={rowLabel} Body={Edit} bodyProps={{ fieldName }} />
    ))

  const textStyle = showPrevious ? "original-product-text" : ""

  const displayRatesheet = showPrevious && !_.isEmpty(previousRate) ? previousRate : ratesheet

  return (
    <div>
      {rowLabel === "Total Cost" && (
        <div className="d-flex flex-column gap-2">
          {showToggleButton && !_.isEmpty(previousRate) && (
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={handleToggle}
              style={{ marginTop: "-70px" }}
              className="align-self-end me-5"
            >
              {showPrevious ? "View Updated" : "View Original"}
            </Button>
          )}
        </div>
      )}

      {rowLabel !== "Total Cost" && (
        <div className="d-flex mb-2">
          {rowLabel === "Total Cost" ? <h5 /> : <h5 className={textStyle}>{rowLabel}</h5>}
          {showToggleButton && !_.isEmpty(previousRate) && (
            <Button
              className="ms-auto"
              size="sm"
              variant="outline-secondary"
              onClick={handleToggle}
            >
              {showPrevious ? "View Updated" : "View Original"}
            </Button>
          )}
        </div>
      )}

      {showToggleButton && showPrevious && (
        <small className="ms-2 mb-2 text-muted fst-italic">Showing Original Values</small>
      )}
      <Table>
        <thead>
          <RateTable.HeaderRow
            editIcon={
              editable && fieldSettings.writeable ? <EditIcon onEdit={toggleModal} /> : undefined
            }
            showPrevious={showPrevious}
          >
            <th colSpan={4} scope="row" aria-label="Header" />
          </RateTable.HeaderRow>
        </thead>
        <tbody>
          <RateTable.Row ratesheet={displayRatesheet} showPrevious={showPrevious}>
            <th colSpan={4} scope="row" className={textStyle}>
              {showRateSelectionButton ? (
                // TODO Switch over to a FormikBootstrap thing here
                <>
                  <input
                    name={ReservedFields.SELECTED_NET_RATESHEET_FIELD}
                    type="radio"
                    checked={selectedRatesheetField === fieldName}
                    className="me-2"
                    value={fieldName}
                    onChange={() =>
                      dispatch(
                        updateFields({
                          values: { [ReservedFields.SELECTED_NET_RATESHEET_FIELD]: fieldName },
                        })
                      )
                    }
                  />
                  <OperatorOnly>{operatorLabel}</OperatorOnly>
                  <CruiseLineOnly>{rowLabel}</CruiseLineOnly>
                </>
              ) : (
                <>
                  <OperatorOnly>{operatorLabel}</OperatorOnly>
                  <CruiseLineOnly>{rowLabel}</CruiseLineOnly>
                </>
              )}
            </th>
          </RateTable.Row>
        </tbody>
      </Table>
    </div>
  )
}

RatesheetRow.defaultProps = {
  editable: false,
  showRateSelectionButton: false,
}

export default RatesheetRow
