import React, { useState, useEffect } from "react"
import _ from "lodash"
import { useUnsavedChanges } from "@/components/Wizard/UnsavedChangesContext"

import { Row, Col, Button, Form } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { FieldArray, Formik, useField } from "formik"

import { useSliceSelector, useSliceActions } from "@/helpers/SliceProvider"

import FormikBootstrap from "@/components/formik-bootstrap"
import FormChangeTracker from "@/components/Wizard/FormikFormChangeTracker"
import FormValidityTracker from "@/components/Wizard/FormikFormValidityTracker"
import { ChatButton } from "@/components/chat"
import { getForm } from "../selectors"
import { CONTACT_TYPE_VALUES, ContactSchema, OperatorFormSchema } from "../Schemas"

const ContactInput = ({ name, formGroupOptions, errors, touched, isValid }) => (
  <Row className="gap-2">
    <FormikBootstrap.TextInput
      name={`${name}.firstName`}
      label="First Name"
      formGroupOptions={formGroupOptions}
    />
    <FormikBootstrap.TextInput
      name={`${name}.lastName`}
      label="Last Name"
      formGroupOptions={formGroupOptions}
    />
    <FormikBootstrap.TextInput
      name={`${name}.jobTitle`}
      label="Job Title"
      formGroupOptions={formGroupOptions}
    />
    <FormikBootstrap.TextInput
      name={`${name}.emailAddress`}
      label="Email Address"
      formGroupOptions={formGroupOptions}
    />
    <FormikBootstrap.TextInput
      name={`${name}.phoneNumber`}
      label="Phone Number"
      formGroupOptions={formGroupOptions}
    />
    <FormikBootstrap.TextInput
      name={`${name}.mobileNumber`}
      label="Mobile Number"
      formGroupOptions={formGroupOptions}
    />
    <FormikBootstrap.Select
      name={`${name}.contactType`}
      label="Contact Type"
      formGroupOptions={formGroupOptions}
    >
      {CONTACT_TYPE_VALUES.map((contactType) => (
        <option key={contactType} value={contactType}>
          {_.startCase(contactType)}
        </option>
      ))}
    </FormikBootstrap.Select>
  </Row>
)

const ContactsSection = (props) => {
  const [field] = useField("contacts")

  const createNewContact = () =>
    ContactSchema.cast({
      firstName: "",
      lastName: "",
      jobTitle: "",
      emailAddress: "",
      phoneNumber: "",
      mobileNumber: "",
      contactType: _.head(CONTACT_TYPE_VALUES),
    })

  return (
    <FieldArray
      name="contacts"
      render={(arrayHelpers) => (
        <>
          <h2 className="h5">Contacts</h2>
          {field.value.map((value, index) => (
            <React.Fragment key={`contact-${index}-${value.emailAddress}`}>
              <ContactInput name={`contacts.${index}`} {...props} />
              <Button className="mt-2" onClick={() => arrayHelpers.remove(index)}>
                Remove Contact
              </Button>
              <hr />
            </React.Fragment>
          ))}
          <Button onClick={() => arrayHelpers.push(createNewContact())}>Add Contact</Button>
        </>
      )}
    />
  )
}

const OperatorForm = (props): JSX.Element => {
  const dispatch = useDispatch()
  const { setUnsavedChanges } = useUnsavedChanges()

  const { updateOperatorDetails } = useSliceActions()

  const { submitting, operator, chats } = useSliceSelector(getForm)

  const [hasChanged, setHasChanged] = useState(false)

  const [valuesAfterChange, setValuesAfterChange] = useState(null)

  const formGroupOptions = {
    md: "12",
    lg: "5",
    as: Col,
    className: "mb-2",
  }

  const formNavigationWithDisabling = props.formNavigation.map((button) =>
    React.cloneElement(button, {
      disabled: hasChanged || button.props.disabled,
    })
  )

  useEffect(() => {
    setUnsavedChanges((prev) => ({ ...prev, companyDetails: hasChanged }))
  }, [hasChanged, setUnsavedChanges])

  const chat = chats.find((chat) => chat.section === "company_details")

  return (
    <Formik
      initialValues={operator}
      validationSchema={OperatorFormSchema}
      onSubmit={(values) =>
        dispatch(updateOperatorDetails({ values })).then(() => {
          setValuesAfterChange(values)
          setHasChanged(false)
        })
      }
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <FormValidityTracker setValidity={props.setValidity} />
          <FormChangeTracker setHasChanged={setHasChanged} currentValues={valuesAfterChange} />
          {props.context.readOnly && operator.changedSinceSubmission && (
            <p>
              <i className="text-warning">
                The values on record for this operator have changed since the application was
                signed.
              </i>
            </p>
          )}
          <div style={{ display: "flex", alignItems: "center" }}>
            <h2 className="h5">{props.title}</h2>
            {chat && (
              <div
                style={{
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  wordBreak: "break-word",
                }}
                key={chat.id}
                className="mt-n2 ms-2"
              >
                <ChatButton chat={chat} />
              </div>
            )}
          </div>
          {!props.context.readOnly && (
            <>
              <p>Review your company information and update if necessary before continuing.</p>

              <i>
                Note that this information will be updated globally (for all cruise lines) if edited
                here
              </i>
            </>
          )}
          <hr />
          <div className="d-flex flex-column gap-3">
            <fieldset disabled={props.context.readOnly}>
              <Row className="mt-2">
                <FormikBootstrap.TextInput
                  name="name"
                  label="Company Name"
                  formGroupOptions={formGroupOptions}
                />
              </Row>
              <Row className="mt-2">
                <FormikBootstrap.TextInput
                  name="registrationNumber"
                  label="Registration Number"
                  formGroupOptions={formGroupOptions}
                />
              </Row>
              <Row className="mt-2">
                <FormikBootstrap.TextInput
                  formGroupOptions={formGroupOptions}
                  name="vatNumber"
                  label="Tax Number"
                />
              </Row>
              <Row className="mt-2">
                <FormikBootstrap.TextAreaInput
                  label="Address"
                  name="address"
                  rows={4}
                  formGroupOptions={formGroupOptions}
                />
              </Row>
              <hr />
              <ContactsSection
                formGroupOptions={formGroupOptions}
                touched={touched}
                errors={errors}
              />
            </fieldset>
            {hasChanged && (
              <p className="text-warning">
                Form has unsaved changes. Click Save below before continuing.
              </p>
            )}
            <div className="d-flex gap-2">
              {formNavigationWithDisabling}
              {!props.context.readOnly && (
                <Button type="submit" variant="secondary" disabled={submitting}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default OperatorForm
