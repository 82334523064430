import React from "react"
import _ from "lodash"

import { isBlank } from "@/utils"

const TextDisplay = ({ value, previousValue = false }) => {
  let displayValue = value

  const textStyle = previousValue ? "original-product-text" : ""

  if (_.isBoolean(value)) {
    displayValue = value ? "Yes" : "No"
  } else if (value === "true") {
    displayValue = "Yes"
  } else if (value === "false") {
    displayValue = "No"
  } else if (_.isArray(value)) {
    displayValue = _.join(value, ", ")
  } else if (_.isNumber(value)) {
    displayValue = value
  } else if (_.has(value, "type") && _.has(value, "value")) {
    if (value.multiple) {
      displayValue = _.join(
        value.value.map((v) => v.label),
        ", "
      )
    } else {
      displayValue = value.value?.label || null
    }
  }

  if (isBlank(displayValue)) {
    displayValue = "Not specified"
  }

  return <span className={`text-wrap ${textStyle}`}>{_.toString(displayValue)}</span>
}

export default TextDisplay
