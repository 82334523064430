import React, { FC, useState } from "react"
import { Button, Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import {
  getProduct,
  getStopsWithComponents,
  getSettings,
  canEdit,
  getOriginalStopComponents,
} from "@/onboarding/features/product/selectors"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { useModal } from "@/helpers/useModal"
import TourStopsEdit from "@/onboarding/features/product/components/tour_stops/Edit"
import EditModal from "@/onboarding/features/product/components/shared/EditModal"
import EditIcon from "@/onboarding/features/product/components/shared/EditIcon"
import { ReservedFields } from "@/onboarding/features/product/models/Product"

import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import * as RateTable from "./RateTable"

const StopSection = ({ stop, showPrevious }): JSX.Element => {
  const textStyle = showPrevious ? "original-product-text" : ""

  return (
    <>
      <tr>
        <td className="fw-bold" colSpan={3}>
          <span className={`ps-2 ${textStyle}`}>{stop.name}</span>
        </td>
      </tr>
      {stop.components.map((component, index) => (
        <RateTable.Row
          key={`${component.name}-${index}`}
          ratesheet={component.ratesheet}
          showPrevious={showPrevious}
        >
          <td>
            <span className={`ps-4 ${textStyle}`}>
              <TextDisplay value={component.name} previousValue={showPrevious} />
            </span>
          </td>
        </RateTable.Row>
      ))}
    </>
  )
}

const StopComponentTable: FC = () => {
  const { tour_stops } = useSelector(getSettings(ReservedFields.TOUR_STOPS))
  const OperatorLabel = tour_stops.required ? "Tour Segments *" : "Tour Segments"
  const { componentTotals } = useSelector(getProduct)
  const { originalComponentTotals } = useSelector(getProduct)

  const [expanded, setExpanded] = useState(true)
  const [showPrevious, setShowPrevious] = useState(false)

  const allStops = useSelector(getStopsWithComponents) || []
  const allPreviousStops = useSelector(getOriginalStopComponents) || []
  const stopComponents = showPrevious ? allPreviousStops : allStops

  const handleToggle = () => setShowPrevious(!showPrevious)

  const toggleModal = useModal((props) => (
    <EditModal {...props} title="Tour Segments" Body={TourStopsEdit} />
  ))

  const totalRatesheet = showPrevious
    ? _.find(originalComponentTotals, (t) => t.name === ReservedFields.TOUR_STOPS)?.total
    : _.find(componentTotals, (t) => t.name === ReservedFields.TOUR_STOPS)?.total || 0

  const textStyle = showPrevious ? "original-product-text" : ""
  const editicon = useSelector(canEdit) ? <EditIcon onEdit={toggleModal} /> : null

  const isEmpty = _.isEmpty(allPreviousStops) && _.isEmpty(allStops)

  return (
    <div>
      {!isEmpty && (
        <div className="d-flex justify-content-between mb-2">
          <h5 className={textStyle}>Tour Segments</h5>
          {tour_stops?.changed && (
            <Button size="sm" variant="outline-secondary" onClick={handleToggle}>
              {showPrevious ? "View Updated" : "View Original"}
            </Button>
          )}
        </div>
      )}
      {showPrevious && (
        <small className="ms-2 mb-2 text-muted fst-italic">Showing Original Values</small>
      )}
      {!isEmpty && (
        <Table>
          <thead>
            <RateTable.HeaderRow showPrevious={showPrevious} editIcon={editicon}>
              <th
                className={`d-flex gap-2 cursor-pointer ${expanded ? "" : "d-none"}`}
                onClick={() => setExpanded(!expanded)}
              >
                <OperatorOnly>
                  <span>{OperatorLabel}</span>
                </OperatorOnly>
                <CruiseLineOnly>
                  <span className={textStyle}>Tour Segments</span>
                </CruiseLineOnly>
                <span className={`fas ${expanded ? "fa-chevron-up" : "fa-chevron-down"}`} />
              </th>
            </RateTable.HeaderRow>
          </thead>
          <tbody>
            {expanded &&
              stopComponents.map((stop, index) => (
                <StopSection
                  key={`${stop.name}-${index}`}
                  stop={stop}
                  showPrevious={showPrevious}
                />
              ))}
            <RateTable.Row ratesheet={totalRatesheet} showPrevious={showPrevious}>
              <th>
                <span className={`ps-2 ${textStyle}`}>Total</span>
              </th>
            </RateTable.Row>
          </tbody>
        </Table>
      )}
    </div>
  )
}

export default StopComponentTable
