/**
 * == Roadmap
 *
 * - Remove any "piercing" dependencies on files within the pricing module
 * - Add "RateEditorConfig"
 *   - readOnly
 *   - defaultRateCategories
 *   - ...
 */
import React from "react"
import _ from "lodash"

import * as FlatRatesheet from "./models/FlatRatesheet"
import FlatRatesheetView from "./components/controls/FlatRatesheetView"
import FlatRatesheetEditor from "./components/controls/FlatRatesheetEditor"

import * as TieredRatesheet from "./models/TieredRatesheet"
import TieredRatesheetView from "./components/controls/TieredRatesheetView"
import TieredRatesheetEditor from "./components/controls/TieredRatesheetEditor"

import * as BulkRatesheet from "./models/BulkRatesheet"
import BulkRatesheetView from "./components/controls/BulkRatesheetView"
import BulkRatesheetEditor from "./components/controls/BulkRatesheetEditor"

import * as SelectableRatesheet from "./models/SelectableRatesheet"
import RatesheetPicker from "./components/controls/RatesheetPicker"

enum Types {
  FlatRatesheet = FlatRatesheet.KIND,
  TieredRatesheet = TieredRatesheet.KIND,
  BulkRatesheet = BulkRatesheet.KIND,
  SelectableRatesheet = SelectableRatesheet.KIND,
}

// TODO add a dedicated model for the SelectableRatesheet type
const CONFIG = {
  "": {
    label: "No Rate",
    editor: () => null,
    view: () => null,
    api: { newRatesheet: () => ({}) },
  },
  [Types.TieredRatesheet]: {
    kind: Types.TieredRatesheet,
    api: TieredRatesheet,
    editor: TieredRatesheetEditor,
    view: TieredRatesheetView,
    label: "Tiered Rate",
    selectable: true,
  },
  [Types.FlatRatesheet]: {
    kind: Types.FlatRatesheet,
    api: FlatRatesheet,
    editor: FlatRatesheetEditor,
    view: FlatRatesheetView,
    label: "Fixed Rate",
    selectable: true,
  },
  [Types.BulkRatesheet]: {
    kind: Types.BulkRatesheet,
    api: BulkRatesheet,
    editor: BulkRatesheetEditor,
    view: BulkRatesheetView,
    label: "Bulk Rate",
    selectable: true,
  },
  [Types.SelectableRatesheet]: {
    kind: Types.SelectableRatesheet,
    api: SelectableRatesheet,
    editor: RatesheetPicker,
    view: RatesheetPicker.View,
    label: "Selectable Rate",
    selectable: false,
  },
}

const getConfig = (value) => (value ? CONFIG[value.kind || ""] : CONFIG[""])

const newComponentFromConfigField =
  (componentName) =>
  ({ value, previousValues = false, ...props }) => {
    const config = getConfig(value)

    if (!config) throw new Error(`No ratesheet config defined for ${value}`)

    // React quirk. Components must have capital name
    const Component = config[componentName]

    return <Component value={value} previousValues={previousValues} {...props} />
  }

const Editor = newComponentFromConfigField("editor")

const View = newComponentFromConfigField("view")

const newRatesheet = ({ kind, defaultRateCategories = [], defaultTiers = [] }) => {
  let ratesheetAPI

  switch (kind) {
    case TieredRatesheet.KIND:
      ratesheetAPI = getConfig({ kind: TieredRatesheet.KIND }).api
      break
    case FlatRatesheet.KIND:
      ratesheetAPI = getConfig({ kind: FlatRatesheet.KIND }).api
      break
    case BulkRatesheet.KIND:
      ratesheetAPI = getConfig({ kind: BulkRatesheet.KIND }).api
      break
    default:
      throw new Error(`TODO: newRatesheet not implemented for kind ${kind}`)
  }

  let ratesheet = ratesheetAPI.newRatesheet()

  _.each(defaultRateCategories, (category) => {
    ratesheet = ratesheetAPI.addCategory(ratesheet, category)
  })

  _.each(defaultTiers, (tierFrom) => {
    const tier = ratesheetAPI.newTier({ tierFrom, rateKeys: defaultRateCategories })
    ratesheet = ratesheetAPI.addTier(ratesheet, tier)
  })

  return ratesheet
}

const types = Types

export default {
  View,
  Editor,
  types,
  newRatesheet,
  CONFIG,
}
