import React, { FC } from "react"
import { useSelector } from "react-redux"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import { getField, getSectionChat, getSettings } from "@/onboarding/features/product/selectors"
import _ from "lodash"
import SectionHolder from "./shared/SectionHolder"
import CostTable from "./costs/CostTable"

const Costs: FC = () => {
  const sectionChat = useSelector(getSectionChat(_.snakeCase("tourCost")))

  const selectedNetRateValue = useSelector(
    getField(ReservedFields.SELECTED_NET_RATESHEET_FIELD)
  ).value
  const overriddenRateField = useSelector(getField(ReservedFields.OVERRIDDEN_NET_RATESHEET))
  const calculatedRateField = useSelector(getField(ReservedFields.CALCULATED_NET_RATESHEET))

  const overriddenRateFieldSettings = useSelector(
    getSettings(ReservedFields.OVERRIDDEN_NET_RATESHEET)
  )
  const calculatedRateFieldSettings = useSelector(
    getSettings(ReservedFields.CALCULATED_NET_RATESHEET)
  )

  const changedComponent =
    (selectedNetRateValue == "overridden_net_ratesheet" &&
      overriddenRateFieldSettings[ReservedFields.OVERRIDDEN_NET_RATESHEET]?.changed) ||
    (selectedNetRateValue == "calculated_net_ratesheet" &&
      calculatedRateFieldSettings[ReservedFields.CALCULATED_NET_RATESHEET]?.changed)

  const locked =
    (selectedNetRateValue == "overridden_net_ratesheet" && !overriddenRateField.writeable) ||
    (selectedNetRateValue == "calculated_net_ratesheet" && !calculatedRateField.writeable)

  // TODO(rate-importing): Support a calculated ratesheet approach
  const importableFields =
    selectedNetRateValue == "overridden_net_ratesheet"
      ? [ReservedFields.OVERRIDDEN_NET_RATESHEET]
      : [
          ReservedFields.TOUR_STOPS,
          ReservedFields.VENUE_COMPONENTS,
          ReservedFields.LOGISTICAL_COMPONENTS,
          ReservedFields.RESTAURANT_COMPONENTS,
          ReservedFields.ENTERTAINMENT_COMPONENTS,
          ReservedFields.REFRESHMENT_COMPONENTS,
          ReservedFields.GUIDE_COMPONENTS,
          ReservedFields.HEADSET_FEE_RATESHEET,
          ReservedFields.OPERATOR_FEE_RATESHEET,
        ]

  return (
    <SectionHolder
      title="Tour Costs"
      showLockedIcon={locked}
      importableFields={importableFields}
      chat={sectionChat}
      changedComponent={changedComponent}
    >
      <CostTable />
    </SectionHolder>
  )
}

export default Costs
