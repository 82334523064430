import React, { FC } from "react"
import { Col, Row, Table } from "react-bootstrap"

import FormikBootstrap from "@/components/formik-bootstrap"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import Pricing from "@/pricing"
import ReferencedValueSelect from "@/onboarding/features/product/components/shared/ReferencedValueSelect"
import { ReferencedValueTypes } from "@/onboarding/features/product/models/Product"
import RateInputs from "../../shared/RateInputs"

const WriteView: FC = () => (
  <div className="d-flex flex-column">
    <Row>
      <Col md={8}>
        <h5>Edit Details</h5>
        <Row className="mb-2">
          <Col md={5}>
            <FormikBootstrap.TextInput name="name" label="Name" />
          </Col>
          <Col md={5}>
            <FormikBootstrap.TextInput name="description" label="Description" />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={5}>
            <ReferencedValueSelect
              name="transportType"
              type={ReferencedValueTypes.VEHICLE_TYPE}
              label="Transport Type"
            />
          </Col>
          <Col md={5}>
            <FormikBootstrap.TextInput name="transportCompanyName" label="Transport Company" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={5}>
            <FormikBootstrap.NumberInput name="availableVehicleCount" label="Available Vehicles" />
          </Col>
          <Col md={5}>
            <FormikBootstrap.NumberInput
              name="maximumCapacityPerVehicle"
              label="Max Capacity per Vehicle"
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={5}>
            <FormikBootstrap.Checkbox name="restroomsAvailable" label="Restrooms Available?" />
            <FormikBootstrap.Checkbox name="wheelchairAccessible" label="Wheelchair Accessible?" />
            <FormikBootstrap.Checkbox
              name="motorizedWheelchairAccessible"
              label="Motorized Wheelchair Accessible?"
              className="text-wrap"
            />
          </Col>
          <Col md={5}>
            <FormikBootstrap.Checkbox
              name="wheelchairLiftAvailable"
              label="Wheelchair Lift Available?"
            />
            <FormikBootstrap.Checkbox name="airConditioned" label="Air Conditioned?" />
          </Col>
        </Row>
      </Col>
      <Col md={4} className="ms-n4">
        <h5 className="mt-4">Edit Rates</h5>
        <RateInputs name="ratesheet" />
      </Col>
    </Row>
  </div>
)

const ReadView = ({ component, hideRates, previousValues = false }): JSX.Element => {
  const textStyle = previousValues ? "original-product-text" : ""

  return (
    <div className="d-flex flex-column gap-2">
      <h5 className={textStyle}>Details</h5>
      <Table>
        <thead>
          <tr>
            <th className={textStyle}>Type</th>
            <th className={textStyle}>Name</th>
            <th className={textStyle} colSpan={2}>
              Description
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td aria-label="Transport Type">
              <TextDisplay value={component.transportType} previousValue={previousValues} />
            </td>
            <td aria-label="Name">
              <TextDisplay value={component.name} previousValue={previousValues} />
            </td>
            <td aria-label="Description" colSpan={2}>
              <TextDisplay value={component.description} previousValue={previousValues} />
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th className={textStyle}>Transport Company</th>
            <th className={textStyle}>Air Conditioned?</th>
            <th className={textStyle}>Available Vehicles</th>
            <th className={textStyle}>Average Max Capacity</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td aria-label="Transport Company Name">
              <TextDisplay value={component.transportCompanyName} previousValue={previousValues} />
            </td>
            <td aria-label="Air Conditioned?">
              <TextDisplay value={component.airConditioned} previousValue={previousValues} />
            </td>
            <td aria-label="Available Vehicle Count">
              <TextDisplay value={component.availableVehicleCount} previousValue={previousValues} />
            </td>
            <td aria-label="Max Capacity per Vehicle">
              <TextDisplay
                value={component.maximumCapacityPerVehicle}
                previousValue={previousValues}
              />
            </td>
          </tr>
        </tbody>
        <thead>
          <tr className="text-wrap">
            <th className={textStyle}>Restrooms Available?</th>
            <th className={textStyle}>Wheelchair Accessible?</th>
            <th className={textStyle}>Motorized Wheelchair Accessible?</th>
            <th className={textStyle}>Wheelchair Lift Available?</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td aria-label="Restrooms Available?">
              <TextDisplay value={component.restroomsAvailable} previousValue={previousValues} />
            </td>
            <td aria-label="Wheelchair Accessible?">
              <TextDisplay value={component.wheelchairAccessible} previousValue={previousValues} />
            </td>
            <td aria-label="Motorized Wheelchair Accessible?">
              <TextDisplay
                value={component.motorizedWheelchairAccessible}
                previousValue={previousValues}
              />
            </td>
            <td aria-label="Wheelchair Lift Available?">
              <TextDisplay
                value={component.wheelchairLiftAvailable}
                previousValue={previousValues}
              />
            </td>
          </tr>
        </tbody>
      </Table>
      {!hideRates && (
        <Col md={3}>
          <h5 className={`mt-2 ${textStyle}`}>Rates</h5>
          <Pricing.View value={component.ratesheet} previousValues={previousValues} />
        </Col>
      )}
    </div>
  )
}

const View = ({
  component,
  previousValues = false,
  editing = false,
  hideRates = false,
}): JSX.Element =>
  editing ? (
    <WriteView />
  ) : (
    <ReadView previousValues={previousValues} component={component} hideRates={hideRates} />
  )

export default View
