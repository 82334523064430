import React, { useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { getProductField, getSettings } from "@/onboarding/features/product/selectors"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import RatesheetRow from "../costs/RatesheetRow"

const Content = ({ changedComponent }): JSX.Element => {
  const { value } = useSelector(getProductField(ReservedFields.HEADSET_COMPONENTS))
  const { headset_components } = useSelector(getSettings(ReservedFields.HEADSET_COMPONENTS))
  const previousValue = headset_components.previousValue || []
  const [showPrevious, setShowPrevious] = useState(false)
  const values = showPrevious ? previousValue : value

  const handleToggle = () => setShowPrevious(!showPrevious)

  const prompt = headset_components.required
    ? "No components loaded. At least one is required."
    : "No Components loaded for this tour"

  const textStyle = showPrevious ? "original-product-text" : ""

  return (
    <div className="d-flex flex-column gap-2">
      {changedComponent && (
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={handleToggle}
          style={{ marginTop: "-38px" }}
          className="align-self-end me-5"
        >
          {showPrevious ? "View Updated" : "View Original"}
        </Button>
      )}
      <Row>
        {showPrevious && (
          <small className="ms-2 mb-2 text-muted fst-italic">
            Showing Original Component Values
          </small>
        )}
        <Col md={6}>
          <h5 className={`mb-1 ${textStyle}`}>Details</h5>
          {_.isEmpty(values) && (
            <div className={showPrevious ? "original-product-text" : ""}>{prompt}</div>
          )}
          {_(values)
            .groupBy("category")
            .map((headsets, category) => (
              <Row key={category}>
                <h3 className={`h6 mt-2 ${textStyle}`}>{_.startCase(category)}</h3>
                <Col className="px-4 gap-2">
                  {headsets.map((headset, index) => (
                    <Row key={index}>
                      <Col md={3}>
                        <span className={`h6 mt-2 fw-bold ${textStyle}`}>Name:</span>
                        <br />
                        <TextDisplay value={headset.name} previousValue={showPrevious} />
                      </Col>
                      <Col md={9}>
                        <span className={`fw-bold ${textStyle}`}>Headset provided:</span>
                        <br />
                        <TextDisplay value={headset.headsetProvided} previousValue={showPrevious} />
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            ))
            .value()}
        </Col>
        <Col md={4}>
          <h5 className={`mt-0 ${textStyle}`}>Rates</h5>
          <RatesheetRow
            fieldName={ReservedFields.HEADSET_FEE_RATESHEET}
            rowLabel="Headset Fee"
            editable={false}
            showPreviousRate={showPrevious}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Content
