import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Button } from "react-bootstrap"
import { useUnsavedChanges } from "@/components/Wizard/UnsavedChangesContext"

import { useSliceSelector, useSliceActions } from "@/helpers/SliceProvider"

import FormikFormChangeTracker from "@/components/Wizard/FormikFormChangeTracker"
import FormikFormValidityTracker from "@/components/Wizard/FormikFormValidityTracker"
import Questionnaire from "@/form_toolkit/components/Questionnaire"

import { ChatButton } from "@/components/chat"
import { getForm } from "../selectors"

const OperatorQuestions = (props): JSX.Element => {
  const dispatch = useDispatch()
  const { setUnsavedChanges } = useUnsavedChanges()
  const { updateOperatorQuestionnaire } = useSliceActions()

  const { operatorQuestionnaire, submitting, chats } = useSliceSelector(getForm)

  const [hasChanged, setHasChanged] = useState(false)
  const [valuesAfterChange, setValuesAfterChange] = useState(null)
  const [skipValidation, setSkipValidation] = useState(false)

  useEffect(() => {
    setUnsavedChanges((prev) => ({ ...prev, questionnaire: hasChanged }))
  }, [hasChanged, setUnsavedChanges])

  const formNavigationWithDisabling = props.formNavigation.map((button) =>
    React.cloneElement(button, {
      disabled: hasChanged || button.props.disabled,
    })
  )

  const chat = chats.find((chat) => chat.section === "operator_questionnaire")

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h2 className="h5">{props.title}</h2>
        {chat && (
          <div
            style={{
              whiteSpace: "normal",
              overflowWrap: "break-word",
              wordBreak: "break-word",
            }}
            key={chat.id}
            className="mt-n2 ms-2"
          >
            <ChatButton chat={chat} />
          </div>
        )}
      </div>
      <hr />
      <Questionnaire
        questionnaire={operatorQuestionnaire}
        onSubmit={(values) => {
          setSkipValidation(true)
          dispatch(updateOperatorQuestionnaire({ values: { values } })).then(() => {
            setValuesAfterChange(values)
            setHasChanged(false)
            setSkipValidation(false)
          })
        }}
        skipValidationOnSubmit={skipValidation}
        readOnly={props.context.readOnly}
      >
        {hasChanged && (
          <p className="text-warning">
            Form has unsaved changes. Click Save below before continuing.
          </p>
        )}
        <div className="d-flex gap-2">
          <FormikFormChangeTracker
            setHasChanged={setHasChanged}
            currentValues={valuesAfterChange}
          />
          <FormikFormValidityTracker setValidity={props.setValidity} />

          {formNavigationWithDisabling}

          {!props.context.readOnly && (
            <Button
              type="submit"
              variant="success"
              onClick={() => setSkipValidation(true)}
              disabled={submitting}
            >
              Save
            </Button>
          )}
        </div>
      </Questionnaire>
    </div>
  )
}

export default OperatorQuestions
