import React, { useState } from "react"
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap"
import { useSelector } from "react-redux"

import {
  getProductField,
  getSectionFieldNames,
  getSettings,
} from "@/onboarding/features/product/selectors"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"

const DisplayValue = ({
  name,
  label,
  requiredField = false,
  incompleteField = false,
}): JSX.Element => {
  const { value } = useSelector(getProductField(name))
  return (
    <div className="d-flex flex-column gap-1">
      <span>
        <b>
          {label}
          <OperatorOnly>
            {requiredField && incompleteField && (
              <span
                data-toggle="tooltip"
                data-placement="top"
                title="This is an incomplete required field"
                className="ms-1"
                style={{ color: "red" }}
              >
                *
              </span>
            )}
          </OperatorOnly>
        </b>
      </span>
      <TextDisplay value={value} />
    </div>
  )
}

const DisplayChangedValue = ({
  name,
  label,
  previousValue,
  requiredField = false,
  incompleteField = false,
}): JSX.Element => {
  const { value } = useSelector(getProductField(name))

  const [showPrevious, setShowPrevious] = useState(false)

  const handleToggle = () => setShowPrevious(!showPrevious)

  return (
    <div className="d-flex flex-column gap-1 cursor-pointer">
      <div
        className="d-flex align-items-center"
        onClick={handleToggle}
        style={{ cursor: "pointer" }}
      >
        <b className="yellow-underline">
          {label}
          <OperatorOnly>
            {requiredField && incompleteField && (
              <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip-incomplete-${name}`}>
                    This is an incomplete required field
                  </Tooltip>
                }
                placement="top"
              >
                <span className="ms-1" style={{ color: "red" }}>
                  *
                </span>
              </OverlayTrigger>
            )}
          </OperatorOnly>
        </b>
        <small className="ms-2 text-muted fst-italic">{showPrevious ? "Original" : ""}</small>
      </div>
      {showPrevious ? (
        <TextDisplay value={previousValue} previousValue />
      ) : (
        <TextDisplay value={value} />
      )}
    </div>
  )
}

const Content = (): JSX.Element => {
  const fieldNames = useSelector(getSectionFieldNames.tourDetails)
  const fieldsSettings = useSelector(getSettings(...fieldNames))

  const renderField = (name, label) => {
    const changedField = fieldsSettings[name]?.changed
    const previousValue = fieldsSettings[name]?.previousValue

    return changedField ? (
      <DisplayChangedValue
        key={name}
        name={name}
        label={label}
        requiredField={fieldsSettings[name]?.required}
        incompleteField={fieldsSettings[name]?.incomplete}
        previousValue={previousValue}
      />
    ) : (
      <DisplayValue
        key={name}
        name={name}
        label={label}
        requiredField={fieldsSettings[name]?.required}
        incompleteField={fieldsSettings[name]?.incomplete}
      />
    )
  }

  return (
    <div className="d-flex flex-column gap-3">
      <Row>
        <Col>
          {renderField(ReservedFields.TARGET_MINIMUM_DEPARTURE_PAX, "Minimum Per Departure")}
        </Col>
        <Col>
          {renderField(ReservedFields.TARGET_MAXIMUM_DEPARTURE_PAX, "Maximum Per Departure")}
        </Col>
        <Col>{renderField(ReservedFields.TARGET_MAXIMUM_CALL_PAX, "Maximum Per Call")}</Col>
        <Col>{renderField(ReservedFields.TARGET_DEPARTURES, "Departures")}</Col>
      </Row>
      <Row>
        <Col>{renderField(ReservedFields.TOUR_TYPE, "Tour Types")}</Col>
        <Col>{renderField(ReservedFields.ACTIVITY_TYPE, "Activity Type")}</Col>
        <Col>{renderField(ReservedFields.ACTIVITY_LEVEL, "Activity Level")}</Col>
        <Col>{renderField(ReservedFields.ADA_COMPLIANT, "ADA Compliant")}</Col>
      </Row>
      <Row>
        <Col>{renderField(ReservedFields.TOUR_DESCRIPTION, "Tour Description")}</Col>
      </Row>
      <Row>
        <Col>
          {renderField(
            ReservedFields.SHORT_TOUR_DESCRIPTION,
            "Tour Description (Less Than 1000 Characters)"
          )}
        </Col>
      </Row>
      <Row>
        <Col>{renderField(ReservedFields.TOUR_NOTES, "Tour Notes")}</Col>
      </Row>
      <Row>
        <Col>
          {renderField(
            ReservedFields.TOP_THREE_REASONS,
            "Top Three Reasons A Guest Should Buy This Tour"
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {renderField(
            ReservedFields.UNAVAILABLE_DAYS,
            "Days On Which The Tour Will Be Unavailable"
          )}
        </Col>
        <Col>
          {renderField(
            ReservedFields.OTHER_RELEVANT_UNAVAILABLE_INFORMATION,
            "Other Relevant Information On Days And Times The Tour Is Not Available"
          )}
        </Col>
      </Row>
      <Row>
        <Col>{renderField(ReservedFields.GROUND_OPERATOR, "Ground Operator")}</Col>
        <Col>{renderField(ReservedFields.EVENING_EXCURSION, "Evening Excursion?")}</Col>
      </Row>
    </div>
  )
}

export default Content
