import React from "react"
import { useSelector } from "react-redux"

import { formatMoney } from "@/helpers/money"
import { displayPrecision } from "@/onboarding/features/product/helpers"
import { getProductCurrency } from "@/onboarding/features/product/selectors"

const RateDisplay = ({
  amount,
  showPrevious = false,
}: {
  amount: number
  showPrevious?: boolean
}): JSX.Element => {
  const currency = useSelector(getProductCurrency)

  return (
    <span style={showPrevious ? { color: "#00448c" } : undefined}>
      {formatMoney(displayPrecision(amount), currency)}
    </span>
  )
}

export default RateDisplay
