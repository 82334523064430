import React, { FC } from "react"
import { Col, Row, Table } from "react-bootstrap"

import FormikBootstrap from "@/components/formik-bootstrap"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import Pricing from "@/pricing"
import ReferencedValueSelect from "@/onboarding/features/product/components/shared/ReferencedValueSelect"
import { ReferencedValueTypes } from "@/onboarding/features/product/models/Product"
import RateInputs from "../../shared/RateInputs"

const WriteView: FC = () => (
  <Row>
    <Col md={8}>
      <h5>Edit Details</h5>
      <Row>
        <Col md={3}>
          <FormikBootstrap.TextInput name="name" label="Name" />
        </Col>
        <Col md={3}>
          <ReferencedValueSelect
            name="entertainmentType"
            type={ReferencedValueTypes.ENTERTAINMENT_TYPE}
            label="Type"
          />
        </Col>
        <Col md={3}>
          <FormikBootstrap.NumberInput name="duration" label="Duration (Minutes)" />
        </Col>
      </Row>

      <Row className="mb-2">
        <Col md={9}>
          <FormikBootstrap.TextInput name="description" label="Description" />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={3}>
          <FormikBootstrap.NumberInput name="capacity" label="Capacity" />
        </Col>
        <Col md={6}>
          <FormikBootstrap.TextInput name="website" label="Website" />
        </Col>
      </Row>
    </Col>

    <Col md={4} className="ms-n4">
      <h5>Edit Rates</h5>
      <RateInputs name="ratesheet" />
    </Col>
  </Row>
)

const ReadView = ({ component, hideRates, previousValues = false }): JSX.Element => {
  const textStyle = previousValues ? "original-product-text" : ""

  return (
    <div className="d-flex flex-column gap-2">
      <h5 className={textStyle}>Details</h5>
      <Table>
        <thead>
          <tr>
            <th className={textStyle}>Name</th>
            <th className={textStyle}>Type</th>
            <th className={textStyle}>Duration (Minutes)</th>
            <th className={textStyle}>Website</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td aria-label="Name">
              <TextDisplay value={component.name} previousValue={previousValues} />
            </td>
            <td aria-label="Entertainment Type">
              <TextDisplay value={component.entertainmentType} previousValue={previousValues} />
            </td>
            <td aria-label="Duration">
              <TextDisplay value={component.duration} previousValue={previousValues} />
            </td>
            <td aria-label="Website">
              <TextDisplay value={component.website} previousValue={previousValues} />
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th className={textStyle}>Capacity</th>
            <th className={textStyle} colSpan={3}>
              Description
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td aria-label="Capacity">
              <TextDisplay value={component.capacity} previousValue={previousValues} />
            </td>
            <td aria-label="Description" colSpan={3}>
              <TextDisplay value={component.description} previousValue={previousValues} />
            </td>
          </tr>
        </tbody>
      </Table>
      {!hideRates && (
        <Col md={3}>
          <h5 className={`mt-2 ${textStyle}`}>Rates</h5>
          <Pricing.View value={component.ratesheet} previousValues={previousValues} />
        </Col>
      )}
    </div>
  )
}

const View = ({
  component,
  previousValues = false,
  editing = false,
  hideRates = false,
}): JSX.Element =>
  editing ? (
    <WriteView />
  ) : (
    <ReadView component={component} hideRates={hideRates} previousValues={previousValues} />
  )

export default View
