import React, { FC } from "react"
import { Col, Row, Table } from "react-bootstrap"

import FormikBootstrap from "@/components/formik-bootstrap"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import Pricing from "@/pricing"
import ReferencedValueSelect from "@/onboarding/features/product/components/shared/ReferencedValueSelect"
import { ReferencedValueTypes } from "@/onboarding/features/product/models/Product"
import RateInputs from "../../shared/RateInputs"

const WriteView: FC = () => (
  <Row className="mb-2">
    <Col md={8}>
      <h5>Edit Details</h5>
      <Row className="mb-2">
        <Col md={5}>
          <FormikBootstrap.TextInput name="name" label="Name" />
        </Col>
        <Col md={5}>
          <FormikBootstrap.TextInput name="description" label="Description" />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={5}>
          <ReferencedValueSelect
            name="refreshmentType"
            type={ReferencedValueTypes.REFRESHMENT_TYPE}
            label="Refreshment Type"
          />
        </Col>
      </Row>
    </Col>

    <Col md={4} className="ms-n4">
      <h5>Edit Rates</h5>
      <RateInputs name="ratesheet" />
    </Col>
  </Row>
)

const ReadView = ({ component, hideRates, previousValues = false }): JSX.Element => {
  const textStyle = previousValues ? "original-product-text" : ""

  return (
    <div className="d-flex flex-column gap-2">
      <h5 className={textStyle}>Details</h5>
      <Table>
        <thead>
          <tr>
            <th className={textStyle}>Refreshment Type</th>
            <th className={textStyle}>Name</th>
            <th className={textStyle}>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td aria-label="Refreshment Type">
              <TextDisplay value={component.refreshmentType} previousValue={previousValues} />
            </td>
            <td aria-label="Name">
              <TextDisplay value={component.name} previousValue={previousValues} />
            </td>
            <td aria-label="Description">
              <TextDisplay value={component.description} previousValue={previousValues} />
            </td>
          </tr>
        </tbody>
      </Table>
      {!hideRates && (
        <Col md={3}>
          <h5 className={`mt-2 ${textStyle}`}>Rates</h5>
          <Pricing.View value={component.ratesheet} previousValues={previousValues} />
        </Col>
      )}
    </div>
  )
}

const View = ({
  component,
  previousValues = false,
  editing = false,
  hideRates = false,
}): JSX.Element =>
  editing ? (
    <WriteView />
  ) : (
    <ReadView component={component} hideRates={hideRates} previousValues={previousValues} />
  )

export default View
