import React, { useState } from "react"
import { Table, Button } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import { canEdit, getField, getProduct, getSettings } from "@/onboarding/features/product/selectors"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { getTitle } from "@/onboarding/features/product/components/product_components/Section"
import { useModal } from "@/helpers/useModal"
import EditIcon from "@/onboarding/features/product/components/shared/EditIcon"
import EditModal from "@/onboarding/features/product/components/shared/EditModal"

import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import * as RateTable from "./RateTable"
import ComponentRateEdit from "./ComponentRateEdit"

const GeneralComponentTable = ({ fieldName }): JSX.Element => {
  const field = useSelector(getField(fieldName))
  const { componentTotals } = useSelector(getProduct)
  const { originalComponentTotals } = useSelector(getProduct)

  const editable = field.writeable && useSelector(canEdit)

  const [expanded, setExpanded] = useState(true)
  const fieldSettings = useSelector(getSettings(fieldName))
  const previousValue = fieldSettings[fieldName]?.previousValue || []

  const [showPrevious, setShowPrevious] = useState(false)

  const handleToggle = () => setShowPrevious(!showPrevious)

  const toggleModal = useModal((props) => (
    <EditModal
      {...props}
      title={getTitle(fieldName)}
      Body={ComponentRateEdit}
      bodyProps={{ fieldName }}
    />
  ))

  const totalRatesheet = showPrevious
    ? _.find(originalComponentTotals, (t) => t.name === fieldName).total
    : _.find(componentTotals, (t) => t.name === fieldName).total

  const operatorTitle = field.required ? `${getTitle(fieldName)} *` : getTitle(fieldName)

  const textStyle = showPrevious ? "original-product-text" : ""

  return (
    <div>
      <div className="d-flex justify-content-between mb-2">
        <h5 className={textStyle}>{getTitle(fieldName)}</h5>
        {fieldSettings[fieldName]?.changed && (
          <Button size="sm" variant="outline-secondary" onClick={handleToggle}>
            {showPrevious ? "View Updated" : "View Original"}
          </Button>
        )}
      </div>
      {showPrevious && (
        <small className="ms-2 mb-2 text-muted fst-italic">Showing Original Values</small>
      )}
      <Table>
        <thead>
          <RateTable.HeaderRow
            showPrevious={showPrevious}
            editIcon={editable && <EditIcon onEdit={toggleModal} />}
          >
            <th
              className={`d-flex gap-2 ${field.value.length > 0 ? "cursor-pointer" : ""}`}
              onClick={() => setExpanded(!expanded)}
            >
              <OperatorOnly>
                <span>{operatorTitle}</span>
              </OperatorOnly>
              <CruiseLineOnly>
                <span className={textStyle}>{getTitle(fieldName)}</span>
              </CruiseLineOnly>
              {field.value.length > 0 && (
                <>
                  <span className={expanded ? "d-none" : ""}>
                    <i key="down-icon" className="fas fa-chevron-down" />
                  </span>
                  <span className={expanded ? "" : "d-none"}>
                    <i key="up-icon" className="fas fa-chevron-up" />
                  </span>
                </>
              )}
            </th>
          </RateTable.HeaderRow>
        </thead>
        <tbody>
          {expanded &&
            (showPrevious ? previousValue : field.value).map((component, index) => (
              <RateTable.Row
                key={`${component.name}-${index}`}
                ratesheet={component.ratesheet}
                showPrevious={showPrevious}
              >
                <td>
                  <span className="ps-2">
                    <TextDisplay value={component.name} previousValue={showPrevious} />
                  </span>
                </td>
              </RateTable.Row>
            ))}
          <RateTable.Row ratesheet={totalRatesheet} showPrevious={showPrevious}>
            <th>
              <span className={`${textStyle} ps-2`}>Total</span>
            </th>
          </RateTable.Row>
        </tbody>
      </Table>
    </div>
  )
}

export default GeneralComponentTable
