import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"

import { ComponentType, newComponent } from "@/onboarding/features/product/models/Component"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import { appendFieldListItem } from "@/onboarding/features/product/Slice"
import {
  canEdit,
  getDefaultRateCategories,
  getProductField,
  getSectionChat,
  getSettings,
} from "@/onboarding/features/product/selectors"
import SectionHolder from "@/onboarding/features/product/components/shared/SectionHolder"
import LoadingSpinner from "@/components/LoadingSpinner"
import { useProductSubmitting } from "@/onboarding/features/product/helpers"

import EditWrapper from "./views/EditWrapper"

const getComponentType = (fieldName: ReservedFields): ComponentType => {
  switch (fieldName) {
    case ReservedFields.VENUE_COMPONENTS:
      return ComponentType.VENUE
    case ReservedFields.LOGISTICAL_COMPONENTS:
      return ComponentType.TRANSPORT
    case ReservedFields.REFRESHMENT_COMPONENTS:
      return ComponentType.REFRESHMENT
    case ReservedFields.RESTAURANT_COMPONENTS:
      return ComponentType.RESTAURANT
    case ReservedFields.ENTERTAINMENT_COMPONENTS:
      return ComponentType.ENTERTAINMENT
    case ReservedFields.GUIDE_COMPONENTS:
      return ComponentType.GUIDE
    case ReservedFields.HEADSET_COMPONENTS:
      return ComponentType.HEADSET
    case ReservedFields.ATTACHMENTS:
      return ComponentType.ATTACHMENT
    default:
      throw new Error(`Unknown component field name: ${fieldName}`)
  }
}

export const getTitle = (fieldName: ReservedFields): string => {
  switch (fieldName) {
    case ReservedFields.VENUE_COMPONENTS:
      return "Sites, Venues and Attractions"
    case ReservedFields.LOGISTICAL_COMPONENTS:
      return "Transportation"
    case ReservedFields.REFRESHMENT_COMPONENTS:
      return "Refreshments and Meals"
    case ReservedFields.RESTAURANT_COMPONENTS:
      return "Restaurants"
    case ReservedFields.ENTERTAINMENT_COMPONENTS:
      return "Entertainment"
    case ReservedFields.GUIDE_COMPONENTS:
      return "Escorts and Guides"
    case ReservedFields.HEADSET_COMPONENTS:
      return "Headsets"
    case ReservedFields.ATTACHMENTS:
      return "Forms, Waivers, Documents and Attachments"
    default:
      throw new Error(`Unknown component field name: ${fieldName}`)
  }
}

const getComponentLabel = (fieldName: ReservedFields): string => {
  switch (fieldName) {
    case ReservedFields.ATTACHMENTS:
      return "Attachment"
    default:
      return "Component"
  }
}

const Section = ({ fieldName }: { fieldName: ReservedFields }): JSX.Element => {
  const dispatch = useDispatch()
  const field = useSelector(getProductField(fieldName))
  const editable = field.writeable && useSelector(canEdit)
  const defaultRateCategories = useSelector(getDefaultRateCategories)
  const [isSubmitting, setSubmitting] = useProductSubmitting()
  const sectionChat = useSelector(getSectionChat(_.snakeCase(fieldName)))
  const fieldsSettings = useSelector(getSettings(fieldName))
  const previousValue = fieldsSettings[fieldName]?.previousValue || []
  const [showPrevious, setShowPrevious] = useState(false)

  const prompt = field.required
    ? `No ${getComponentLabel(fieldName)}s loaded. At least one is required.`
    : `No ${getComponentLabel(fieldName)}s loaded for this tour`

  const components = showPrevious ? previousValue : field.value

  const addComponent = () => {
    setSubmitting(true)

    dispatch(
      appendFieldListItem({
        fieldName,
        value: newComponent({ kind: getComponentType(fieldName), defaultRateCategories }),
      })
    )
  }

  const handleToggle = () => setShowPrevious(!showPrevious)

  return (
    <SectionHolder
      title={getTitle(fieldName)}
      showLockedIcon={!field.writeable}
      missingRequiredComponent={field.required && _.isEmpty(components)}
      chat={sectionChat}
      changedComponent={fieldsSettings[fieldName]?.changed}
    >
      <div className="d-flex flex-column gap-2">
        {fieldsSettings[fieldName]?.changed && (
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={handleToggle}
            style={{ marginTop: "-38px" }}
            className="align-self-end me-5"
          >
            {showPrevious ? "View Updated" : "View Original"}
          </Button>
        )}
        {showPrevious && (
          <small className="ms-2 text-muted fst-italic">Showing Original Component Values</small>
        )}
        {_.isEmpty(components) && (
          <div style={showPrevious ? { color: "#00448c" } : {}}>{prompt}</div>
        )}
        {components.map((component, index) => (
          <EditWrapper
            key={`${component.name}-${index}`}
            fieldName={fieldName}
            index={index}
            component={component}
            editable={editable}
            originalValues={showPrevious}
          />
        ))}
        {editable && (
          <div className="d-flex gap-2">
            <Button
              size="sm"
              variant="outline-primary"
              onClick={addComponent}
              disabled={isSubmitting}
            >
              <i className="fas fa-plus me-2" />
              {`Add ${getComponentLabel(fieldName)}`}
            </Button>
            {isSubmitting && <LoadingSpinner />}
          </div>
        )}
      </div>
    </SectionHolder>
  )
}

export default Section
