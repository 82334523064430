import React from "react"
import { Row, Col, Form } from "react-bootstrap"
import { Formik } from "formik"
import _ from "lodash"

import Field from "./Field"
import { yupSchema } from "../schema"

/**
 * TODOS
 * - Not a big fan of having a props key of the same name as the element I'm rendering, maybe rather just pass it all down?
 */
const Section = ({ section, config }): JSX.Element => (
  <div key={section.idx}>
    <h3 className="h6"> {section.title} </h3>
    <hr />
    {_.map(section.questions, (q, idx) => (
      <Question question={q} key={idx} config={config} />
    ))}
  </div>
)

const QuestionPrompt = ({ question }): JSX.Element => (
  <p>
    {question.prompt} {question.field.required && <b>*</b>}{" "}
  </p>
)

// TODO read name key from the server instead
const Question = ({ question, config }): JSX.Element => (
  <NumberedItem config={config}>
    {config.prompts && <QuestionPrompt question={question} />}
    <Field field={question.field} name={question.id} />
  </NumberedItem>
)

const Numbering = ({ config, children }) => {
  if (config.numbering) {
    return <ol>{children}</ol>
  }
  return <div>{children}</div>
}

const NumberedItem = ({ config, children }) => {
  if (config.numbering) {
    return <li>{children}</li>
  }
  return <>{children}</>
}

/**
 * Lazy. If the prompts and numbering aren't necessary, then is the use of the `Questionnaire` model
 * even necessary / justified?
 */
const Questionnaire = ({
  questionnaire,
  onSubmit,
  readOnly,
  children,
  numbering = true,
  prompts = true,
  skipValidationOnSubmit = false,
}): JSX.Element => {
  const config = { numbering, readOnly, prompts }

  const initialValues = _.merge(
    {},
    ..._(questionnaire.sections)
      .flatMap("questions")
      .map((q) => ({ [q.id]: q.field.value || "" }))
      .value()
  )

  const schema = skipValidationOnSubmit ? undefined : yupSchema(questionnaire)

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <fieldset disabled={readOnly === true}>
            <Row className="mb-4">
              <Col>
                <div>
                  <Numbering config={config}>
                    {_.map(questionnaire.sections, (s, idx) => (
                      <Section key={idx} section={s} config={config} />
                    ))}
                  </Numbering>
                </div>
              </Col>
            </Row>
          </fieldset>
          {children}
        </Form>
      )}
    </Formik>
  )
}

export default Questionnaire
