import React, { FC } from "react"
import { useSelector } from "react-redux"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import { getSectionChat, getSettings } from "@/onboarding/features/product/selectors"
import _ from "lodash"
import SectionHolder from "./shared/SectionHolder"
import Content from "./extra_fees/Content"
import Edit from "./extra_fees/Edit"

const ExtraFees: FC = () => {
  const sectionChat = useSelector(getSectionChat(_.snakeCase("extraFees")))
  const { extra_fees } = useSelector(getSettings(ReservedFields.EXTRA_FEES))
  const isWriteable = extra_fees?.writeable ?? false
  const isChanged = extra_fees?.changed ?? false

  return (
    <SectionHolder
      title="Extra Fees"
      editComponent={isWriteable ? Edit : null}
      editMethod="inline"
      showLockedIcon={!isWriteable}
      chat={sectionChat}
    >
      <Content changedComponent={isChanged} />
    </SectionHolder>
  )
}

export default ExtraFees
