import React from "react"
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import { getField, getSettings } from "@/onboarding/features/product/selectors"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"

const FieldValue = ({ value, showPrevious, previousValue }) => (
  <td className="align-top text-wrap">
    {showPrevious && previousValue !== undefined ? (
      <TextDisplay value={previousValue} previousValue />
    ) : _.isArray(value) ? (
      <div className="d-flex flex-column gap-1">
        {value.map((val, index) => (
          <TextDisplay key={index} value={val} />
        ))}
      </div>
    ) : value !== undefined ? (
      <TextDisplay value={value} />
    ) : (
      <span className="text-muted">No value available</span>
    )}
  </td>
)

const FieldRow = ({ fieldName, fieldSettings, showPrevious }) => {
  const { value } = useSelector(getField(fieldName))

  return (
    <tr>
      <td className="align-top col-1">
        <b>{_.startCase(fieldName)}</b>
        <OperatorOnly>
          {fieldSettings?.required && fieldSettings?.incomplete && (
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip-incomplete-${fieldName}`}>
                  This is an incomplete required field
                </Tooltip>
              }
              placement="top"
            >
              <span className="ms-1" style={{ color: "red" }}>
                *
              </span>
            </OverlayTrigger>
          )}
        </OperatorOnly>
      </td>
      <FieldValue
        value={value}
        showPrevious={showPrevious}
        previousValue={fieldSettings?.previousValue}
      />
    </tr>
  )
}

const FieldTable = ({ fieldNames, noFieldsMessage = "No content loaded", showPrevious }) => {
  const fieldsSettings = useSelector(getSettings(...fieldNames))

  if (fieldNames.length === 0) {
    return <p className="mb-0">{noFieldsMessage}</p>
  }

  return (
    <Table borderless size="sm" className="ms-n4">
      <tbody>
        {fieldNames.map((fieldName) => (
          <FieldRow
            key={fieldName}
            fieldName={fieldName}
            fieldSettings={fieldsSettings[fieldName]}
            showPrevious={showPrevious}
          />
        ))}
      </tbody>
    </Table>
  )
}

export default FieldTable
