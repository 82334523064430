import React, { useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import { getProductField, getSettings } from "@/onboarding/features/product/selectors"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import RateDisplay from "@/onboarding/features/product/components/shared/RateDisplay"

const FeeDisplay = ({ fee, showPrevious = false }): JSX.Element => {
  const textStyle = showPrevious ? "original-product-text" : ""

  return (
    <Col md={6} className="d-flex py-2">
      <div style={{ width: "100px" }}>
        <RateDisplay amount={fee.ratesheet.rates.adult} showPrevious={showPrevious} />
      </div>
      <div>
        {fee.payableByGuest ? (
          <span className={textStyle}>
            {fee.name} <b>(guest)</b>
          </span>
        ) : (
          <span className={textStyle}>{fee.name}</span>
        )}
      </div>
    </Col>
  )
}

const Content = ({ changedComponent }): JSX.Element => {
  const { value } = useSelector(getProductField(ReservedFields.EXTRA_FEES))
  const { extra_fees } = useSelector(getSettings(ReservedFields.EXTRA_FEES))
  const previousValue = extra_fees?.previousValue || []
  const [showPrevious, setShowPrevious] = useState(false)
  const components = showPrevious ? previousValue : value
  const textStyle = showPrevious ? "original-product-text" : ""

  const handleToggle = () => setShowPrevious(!showPrevious)

  const prompt = extra_fees?.required
    ? "No Extra Fees loaded. This is a required component."
    : "No Extra Fees loaded for this tour"

  return (
    <div className="d-flex flex-column gap-4 px-4">
      {changedComponent && (
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={handleToggle}
          style={{ marginTop: "-38px" }}
          className="align-self-end me-3"
        >
          {showPrevious ? "View Updated" : "View Original"}
        </Button>
      )}
      {showPrevious && (
        <small className="ms-2 text-muted fst-italic">Showing Original Component Values</small>
      )}
      {_.isEmpty(components) && <div className={`ms-n4 ${textStyle}`}>{prompt}</div>}
      {_(components)
        .groupBy("category")
        .map((fees, category) => (
          <Row key={category}>
            <h3 className={`h6 ${textStyle}`}>{category}</h3>
            <Col>
              <Row className="px-4">
                {fees.map((fee, index) => (
                  <FeeDisplay key={index} fee={fee} showPrevious={showPrevious} />
                ))}
              </Row>
            </Col>
          </Row>
        ))
        .value()}
    </div>
  )
}

export default Content
