import _ from "lodash"
import { isBlank } from "@/utils"

const TextValue = (value) => {
  const displayValue = value

  if (_.isBoolean(value)) {
    return value ? "Yes" : "No"
  }

  if (value === "true") {
    return "Yes"
  }

  if (value === "false") {
    return "No"
  }

  if (_.isArray(value)) {
    return _.join(value, ", ")
  }

  if (_.isNumber(value)) {
    return _.toString(displayValue)
  }

  if (_.has(value, "type") && _.has(value, "value")) {
    if (value.multiple) {
      return _.isEmpty(value.value)
        ? "Not specified"
        : _.join(
            value.value.map((v) => v.label),
            ", "
          )
    }
    return value.value?.label || "Not specified"
  }

  if (isBlank(displayValue)) {
    return "Not specified"
  }
  return _.toString(displayValue)
}

export default TextValue
