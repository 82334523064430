import React, { useState } from "react"
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap"
import { useSelector } from "react-redux"

import {
  getProductField,
  getSectionFieldNames,
  getSettings,
} from "@/onboarding/features/product/selectors"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"

const DisplayValue = ({ name, label, requiredField, incompleteField }): JSX.Element => {
  const { value } = useSelector(getProductField(name))

  return (
    <div className="d-flex flex-column gap-1 mb-1">
      <span>
        <b>
          {label}
          <OperatorOnly>
            {requiredField && incompleteField && (
              <span
                data-toggle="tooltip"
                data-placement="top"
                title="This is an incomplete required field"
                className="ms-1"
                style={{ color: "red" }}
              >
                *
              </span>
            )}
          </OperatorOnly>
        </b>
      </span>
      <TextDisplay value={value} />
    </div>
  )
}

const DisplayChangedValue = ({
  name,
  label,
  previousValue,
  requiredField,
  incompleteField,
}): JSX.Element => {
  const { value } = useSelector(getProductField(name))
  const [showPrevious, setShowPrevious] = useState(false)

  const handleToggle = () => setShowPrevious(!showPrevious)

  return (
    <div className="d-flex flex-column gap-1 cursor-pointer mb-1">
      <div
        className="d-flex align-items-center"
        onClick={handleToggle}
        style={{ cursor: "pointer" }}
      >
        <b className="yellow-underline">
          {label}
          <OperatorOnly>
            {requiredField && incompleteField && (
              <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip-incomplete-${name}`}>
                    This is an incomplete required field
                  </Tooltip>
                }
                placement="top"
              >
                <span className="ms-1" style={{ color: "red" }}>
                  *
                </span>
              </OverlayTrigger>
            )}
          </OperatorOnly>
        </b>
        <small className="ms-2 text-muted fst-italic">{showPrevious ? "Original" : ""}</small>
      </div>
      {showPrevious ? (
        <TextDisplay value={previousValue} previousValue />
      ) : (
        <TextDisplay value={value} />
      )}
    </div>
  )
}

const Content = (): JSX.Element => {
  const fieldNames = useSelector(getSectionFieldNames.additionalDetails)
  const fieldsSettings = useSelector(getSettings(...fieldNames))

  const renderField = (name, label) => {
    const changedField = fieldsSettings[name]?.changed
    const previousValue = fieldsSettings[name]?.previousValue

    return changedField ? (
      <DisplayChangedValue
        key={name}
        name={name}
        label={label}
        requiredField={fieldsSettings[name]?.required}
        incompleteField={fieldsSettings[name]?.incomplete}
        previousValue={previousValue}
      />
    ) : (
      <DisplayValue
        key={name}
        name={name}
        label={label}
        requiredField={fieldsSettings[name]?.required}
        incompleteField={fieldsSettings[name]?.incomplete}
      />
    )
  }

  return (
    <div className="d-flex flex-column gap-4">
      <Row>
        <Col>
          <h3 className="h6">Tour Restrictions:</h3>
          <Row className="d-flex gap-4">
            <Col>{renderField(ReservedFields.AGE_RESTRICTIONS, "Age")}</Col>
            <Col>{renderField(ReservedFields.HEIGHT_RESTRICTIONS, "Height")}</Col>
            <Col>{renderField(ReservedFields.WEIGHT_RESTRICTIONS, "Weight")}</Col>
            <Col>
              {renderField(ReservedFields.MEDICAL_CONDITION_RESTRICTIONS, "Medical Conditions")}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex gap-4">
        <Col>
          <h3 className="h6">This tour provides:</h3>
          <Row className="mb-3">
            {renderField(ReservedFields.INSTRUCTORS_PROVIDED, "Instructors")}
            {renderField(ReservedFields.CHANGING_FACILITIES_PROVIDED, "Changing Facilities")}
            {renderField(ReservedFields.STORAGE_LOCKERS_PROVIDED, "Storage Lockers")}
            {renderField(ReservedFields.TOWELS_PROVIDED, "Towels For Water Activities")}
            {renderField(ReservedFields.WAIVER_FORMS_REQUIRED, "Waiver forms to be signed")}
          </Row>
        </Col>
        <Col>
          <h3 className="h6">This tour is suitable for people with:</h3>
          <Row className="mb-3">
            {renderField(ReservedFields.WHEELCHAIR_SUITABLE, "Wheelchairs")}
            {renderField(ReservedFields.BLIND_SUITABLE, "Blindness")}
            {renderField(ReservedFields.DEAF_SUITABLE, "Deafness")}
            {renderField(ReservedFields.WALKING_DIFFICULTY_SUITABLE, "Walking Difficulties")}
          </Row>
        </Col>
        <Col>
          {renderField(ReservedFields.INCLUDED_EQUIPMENT, "What equipment is included on tour?")}
        </Col>
        <Col>
          {renderField(
            ReservedFields.GUEST_GUIDANCE,
            "What should guests be advised to bring on tour?"
          )}
        </Col>
      </Row>
    </div>
  )
}

export default Content
