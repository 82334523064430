import React, { useState } from "react"
import { Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import { getTimeline } from "@/onboarding/features/product/selectors"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { isBlank } from "@/utils"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"

const formatDuration = (duration: number) => {
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60

  if (hours === 0) {
    return `${minutes}m`
  }

  if (minutes === 0) {
    return `${hours}h`
  }

  return `${hours}h${minutes}m`
}

const formatTimes = ({ startTime, endTime }): string => {
  if (isBlank(startTime) || isBlank(endTime)) {
    return ""
  }

  return `${startTime} - ${endTime}`
}

const StopRow = ({ stop, isPrevious = false, showPrevious }): JSX.Element => {
  const time = isPrevious ? stop.time : formatTimes(stop)

  return (
    <tr>
      <td>
        <TextDisplay value={time} previousValue={showPrevious} />
      </td>
      <td>
        <TextDisplay value={formatDuration(stop.duration)} previousValue={showPrevious} />
      </td>
      <td>
        <TextDisplay value={stop.name} previousValue={showPrevious} />
      </td>
      <td>
        <TextDisplay value={stop.stopType} previousValue={showPrevious} />
      </td>
      <td>
        <TextDisplay value={stop.transferType} previousValue={showPrevious} />
      </td>
      <td>
        <TextDisplay value={stop.steps} previousValue={showPrevious} />
      </td>
      <td>
        <TextDisplay value={stop.terrain} previousValue={showPrevious} />
      </td>
      <td>
        <TextDisplay value={stop.bathroomsAvailable} previousValue={showPrevious} />
      </td>
      <td>
        <div className="d-flex flex-column gap-1">
          {stop.components.map(({ name }, index) => (
            <TextDisplay key={`${name}-${index}`} value={name} previousValue={showPrevious} />
          ))}
        </div>
      </td>
      <td>
        <TextDisplay value={stop.description} previousValue={showPrevious} />
      </td>
    </tr>
  )
}

const Timeline = ({
  requiredField = false,
  incompleteField = false,
  previousStops = null,
}): JSX.Element => {
  const [showPrevious, setShowPrevious] = useState(false)
  const stops = useSelector(getTimeline)

  const handleToggle = () => {
    if (previousStops !== null) {
      setShowPrevious(!showPrevious)
    }
  }

  const hasNoSegments =
    (_.isEmpty(stops) && !showPrevious) || (_.isEmpty(previousStops) && showPrevious)

  const textStyle = showPrevious ? "original-product-text" : ""
  const labelStyle = previousStops ? "yellow-underline" : ""

  return (
    <>
      <h5 className={`mt-2 ${labelStyle}`} onClick={handleToggle}>
        Segments
      </h5>

      {hasNoSegments ? (
        <>
          {previousStops !== null && showPrevious && (
            <small className="ms-2 text-muted fst-italic">Showing Original Segments</small>
          )}
          <p className="mb-0" style={{ color: showPrevious ? "#00448c" : "inherit" }}>
            No segments loaded
            <OperatorOnly>
              {requiredField && incompleteField && (
                <span
                  data-toggle="tooltip"
                  data-placement="top"
                  title="This is an incomplete required component"
                  className="ms-1"
                  style={{ color: "red" }}
                >
                  *
                </span>
              )}
            </OperatorOnly>
          </p>
        </>
      ) : (
        <>
          {previousStops !== null && showPrevious && (
            <small className="ms-2 text-muted fst-italic">Showing Original Segments</small>
          )}

          <Table>
            <thead>
              <tr>
                <th className={textStyle}>Time</th>
                <th className={textStyle}>Duration</th>
                <th className={textStyle}>Name</th>
                <th className={textStyle}>Type</th>
                <th className={textStyle}>Transfer Type</th>
                <th className={textStyle}>Steps</th>
                <th className={textStyle}>Terrain</th>
                <th className={textStyle}>Bathrooms</th>
                <th className={textStyle}>Components</th>
                <th className={textStyle}>Description</th>
              </tr>
            </thead>
            <tbody>
              {(showPrevious && previousStops ? previousStops : stops).map((stop, index) => (
                <StopRow
                  key={`${stop.name}-${index}`}
                  stop={stop}
                  isPrevious={showPrevious}
                  showPrevious={showPrevious}
                />
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  )
}

export default Timeline
